.table-container{
    width: 100%;overflow: hidden;
    position: relative;
    // &::before{
    //     position: absolute;content: "";z-index: -1;border-radius: 15px;
    //     left: -1.5px;right: -1.5px;bottom: -1.5px;top: -1.5px;opacity: 1;
    //     background: linear-gradient(to right,  rgba(2,45,245,1) 0%,rgba(16,130,190,1) 100%); 
    // }
    .table-container-inner{
        width: 100%;overflow-x: auto;
        scrollbar-color: $color transparent;
        table{position: relative;text-align:left;border-radius:5px;background-color: $black;
            &.trans{background-color: transparent;
                tr:nth-child(even) td{background-color: transparent;}
                tr:nth-child(odd) td{background-color: #232323;}
            }
            &.table-bordered{border:none;}
            &.big{
                @media (max-width:1400px) {min-width: 1200px;}
            }
            &.small{
                @media (max-width:767px) {min-width: 700px;}    
            }
            &:before{
                position: absolute;content: "";left: 0;right: 0;bottom: 0;top: 0;
                border: 1px solid transparent;border-radius: 20px;z-index: -1;
                background: linear-gradient(to bottom,  rgba(56,63,105,1) 0%,rgba(21,31,96,0.02) 50%,rgba(22,32,96,0) 51%,rgba(56,63,105,1) 100%) border-box;  
                -webkit-mask:linear-gradient(#fff 0 0) padding-box,  linear-gradient(#fff 0 0);
                -webkit-mask-composite: xor; mask-composite: exclude; 
            }
            
            td{
                font-size: 16px;color: $white;font-weight: 400;border: none;
                border-left: 1px solid #555555;
                &:first-child{border-left: 0;}
            }
            thead{border-radius: 5px 5px 0 0;}
            th{color: $white;font-weight: 700;border: none;
                background-color: $darkgrey;border-left: 1px solid #555555;
                &:first-child{border-radius:5px 0 0 0px;border-left: 0;}
                &:last-child{border-radius:0 5px 0px 0;}
            }
            tr:last-child{
                td{
                    &:first-child{border-radius:0px 0 0 5px;}
                    &:last-child{border-radius:0 0px 5px 0;}
                } 
            }
            tr:nth-child(even){
                td{background-color: #232323;}
            }
            &.transaction-table{
                tr{
                    td:last-child,td:nth-last-child(2),
                    th:not(:first-child){text-align: center;}
                    th:not(:first-child){border-left: 1px solid rgba(255,255,255,0.20);}
                    td:not(:first-child){border-left: 1px solid rgba(255,255,255,0.20);}
                }
            }
            &.td-brder{
                tbody{border: none;}
                tr{
                    td{border: none;}
                    td:not(:first-child){border-left: 1px solid rgba(255,255,255,0.20);}
                }
            }
            &.h-b-less{
                tr{
                    th{border-bottom: 1px solid $bordercolor;}
                    td:not(:first-child){border-left: 1px solid rgba(255,255,255,0.20);}
                }
            }
        }
    }
}