// Inner Pages Starts Here
.inner-page{
    padding-top: 200px;
    background: linear-gradient(to bottom,  rgba(7,15,43,0) 70%,rgba(84,5,5,1) 100%); 
}

// Road Map List
.roadmap-list{
    display: inline-block;width: 100%;text-align: center;margin-top:30px;
    li{
        display: flex;align-items: center;width: 100%;
        &:nth-child(even){
            justify-content: flex-start;
            .heading{
                justify-content: flex-end;
                // @media(max-width:767px){
                //     justify-content: flex-end;flex-direction: row-reverse;text-align: left;align-items: center;
                // }
                &:before{
                    right: 60px;left: unset;
                    // @media(max-width:767px){
                    //     right: unset;left:45px;
                    // }
                }
            }
        }
        &:nth-child(odd){
            justify-content: flex-end;
            .heading{
                justify-content: flex-start;
                
            }
        }
        &:last-child .heading:before{display: none;}
        .heading{
            width: calc(50% + 55px);display: inline-flex;
            align-items: center;margin-bottom: 30px;
            position: relative;
            // @media(max-width:767px){width: 100%;}
            &:before{
                position: absolute;content: "";height:30px;
                left: 45px;top: 100%;
                border-left-width: 0px;
                border-color: $white;
                border-style: dotted;
            }
        }
        h5{
            @include Font-outfit;font-size: 20px;font-weight: 400;color: $white;
            margin: 0;text-align: left;
            @media(max-width:767px){font-size: 16px;}
        }
        span{display: inline-flex;width: 80px;height: 80px;min-width: 80px;
            font-size: 22px;color: $white;@include Font-outfit;opacity:1;
            margin: 0 15px;position: relative;justify-content:center;align-items: center;
        }
        i{font-style: normal !important;position: relative;z-index: 2;}
        &.active{
            span{opacity: 1;}
            .heading:before{border-style: solid;}
        }
    }
}
// Road Map List

// Latest New Panel
.latest-news-panel{
    padding-top: 150px;padding-bottom: 150px;position: relative;
    @media(max-width:1400px){padding-top: 120px;padding-bottom:120px;}
    @media(max-width:991px){padding-top: 80px;padding-bottom:80px;}
    @media(max-width:767px){padding-top: 50px;padding-bottom:50px;}

    .bg-layer{opacity: 0.2;
        background-repeat: no-repeat;
        background-size: auto;
        background-position: center center;
    }
    &::before{
        position: absolute;content: "";
        left: 0;top: 0;right: 0;height: 150px;z-index: 3;
        background: linear-gradient(to bottom,  rgba(0,0,0,1) 0%,rgba(0,0,0,0) 100%);

    }
    &::after{
        position: absolute;content: "";
        left: 0;bottom: 0;right: 0;height: 150px;z-index: 3;
        background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%);
    }
    .carousel-indicators{
        button{
            width: 8px;height: 8px;
            border-radius: 50%;border: none;border-radius: 50%;
            margin: 0 5px;opacity: 0.5;@include trans;
            &:hover,
            &.active{opacity: 1;}
        }
    }
    .carousel-control-prev,
    .carousel-control-next{
        width: auto;
        .visually-hidden{display: none;}
    }
    .carousel-control-prev{
        left: -30px;
    }
    .carousel-control-next-icon{
        background-image: none;
        background-image: url(../Assets/img/Icons/carosle-arrow-right.png);
    }
    .carousel-control-prev-icon{
        background-image: none;
        background-image: url(../Assets/img/Icons/carosle-arrow-left.png);
    }
}
// Latest New Panel

// Faq Container
.faq-container {
    .nav-tabs {justify-content: center;
        .nav-item{ 
            width: auto;
            button{text-transform: uppercase;font-size:18px;min-width: 160px;
                @include Font-titllium;
                @media(max-width:767px){min-width: 150px;font-size:14px;}
                @media(max-width:575px){min-width: unset;}
                &.active,
                &:hover{
                    background-color: rgba(0,0,0,0.20);
                    color: $lighteryellow;
                }
                &.active{
                    box-shadow: 0 0 30px rgba(255,255,255,0.30) !important;
                }
            }
        }
    }
}
// Faq Container

// Partnership Panel
.partnership-panel{padding-top: 100px;padding-bottom: 50px;position: relative;
    @media(max-width:1400px){padding-top: 80px;padding-bottom:50px;}
    @media(max-width:991px){padding-top: 70px;padding-bottom:50px;}
    @media(max-width:767px){padding-top: 50px;padding-bottom:50px;}
    .bg-layer{
        background-position: center top;
    }
    &::before{
        position: absolute;content: "";
        left: 0;top: 0;right: 0;height: 150px;z-index: 3;
        background: linear-gradient(to bottom,  rgba(0,0,0,1) 0%,rgba(0,0,0,0) 100%);

    }
    &::after{
        position: absolute;content: "";
        left: 0;bottom: 0;right: 0;height: 150px;z-index: 3;
        background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%);
    }
}
// Partner List
.partner-list{text-align: center;margin-top:20px;
    li{padding: 0 15px;width: 24.5%;display: inline-block;margin-bottom:20px;
        @media(max-width:991px){width: 33%;display:inline-block;}
        @media(max-width:767px){width: 49%;max-width:49%;}
    }
}
// Partner List

.faq-container{@include gradient;}
.How-to-buy-page{
    .bg-layer{background-repeat: no-repeat;background-position: center left;}
    h4{color: $purple;margin-bottom: 10px;
        a{color: $yellow;text-decoration: underline !important;
            &:hover{color: $color;}
        }
    }

    @media(max-width:991px){
        .buy-step-list li{
            &:not(:last-child)::before{left: -14px;}
            &:not(:last-child)::after{left: -25px;}
            &:first-child::after{left: -28px;}
            &:last-child::after{left: -21px;}
        }
    }
    @media(max-width:767px){
        .text-center{text-align: left !important;}
        .buy-step-list li{
            &:not(:last-child)::before{left: -8px;}
            &:not(:last-child)::after{left: -19px;}
            &:first-child::after{left: -22px;}
            &:last-child::after{left: -15px;}
        }
    }
    @media(max-width:575px){
        .buy-step-list li{
            &:not(:last-child)::before{left: 10px;}
            &:not(:last-child)::after{left: -2px;}
            &:first-child::after{left: -5px;}
            &:last-child::after{left: 3px;}
        }
    }
}
.rewards-page{
    .bg-layer{background-repeat: no-repeat;background-position: center left;}
    h4{color: $purple;margin-bottom: 10px;
        a{color: $yellow;text-decoration: underline !important;
            &:hover{color: $color;}
        }
    }
}
.buy-step-list{
    li{
        position: relative;margin-bottom:50px;padding-left:40px;
        h4{color: $white;}
        img{width: 100%;max-width: 400px;}
        p{color: $white;font-size: 20px;margin-bottom: 30px;}
        &:not(:last-child):before{
            position: absolute;content:"";width: 2px;top:26px;bottom: -55px;
            background-color: $color;left: -30px;box-shadow: 0 0 10px 10px rgba(83,53,242,0.25);
        }
        &:after{
            position: absolute;content:"";width: 26px;height:26px;top:5px;left: -42px;border-radius: 50%;
            box-shadow: 0 0 10px 10px rgba(83,53,242,0.25);border: 5px solid $color;background-color: transparent;
        }
        &:first-child:after{background-color: $color;width: 32px;height: 32px;left: -45px;}
        &:last-child:after{background-color: $color;width: 16px;height: 16px;left: -37px;}
    }
}
// Inner Pages Ends Here


// Terms And Policy 
.terms-policy{
    padding-top: 150px;
    background: linear-gradient(to bottom,  rgba(7,15,43,0) 70%,rgba(84,5,5,0.5) 100%);
    &.pp-bg{
        background: linear-gradient(to bottom, rgba(7, 15, 43, 0) 70%, #070F2B 100%);
    }

    h1{margin-bottom: 50px;@include Font-chakra;}
    h2,h3,p{text-align: left;margin-bottom: 20px;@include Font-outfit;}
    h2,h3{color: $white;@include Font-chakra;}
    p{color: $grey;@include Font-outfit;font-size: 20px;}
    p a{color: $yellow;font-size: 20px;@include Font-chakra;
        text-decoration: underline !important;
        &:hover{color: $yellow;}
        @media(max-width:767px){font-size: 16px;}
    }
}
// Terms And Policy


.centerr{text-align: -webkit-center !important;}
.giveaway-pnl{
    position: relative;padding-top: 200px;text-align: center;
    @media (max-width:991px) {padding-top: 150px;}
    @media (max-width:767px) {padding-top: 120px;
        h1{font-size: 40px;margin-bottom:0px;
            span{width: 100%;display: inline-block;}
        }
        .reg-btn.br-radius{width: 100%;}
    }
    .bg-layer{
        background-repeat: no-repeat;
        background-position: center top;
    }
    .text-panel{
        text-align: center;max-width: 900px;margin: 0 auto 50px;
        h4{line-height: 35px;color: $bordercolor; 
            b{color: $bordercolor;}
            @media (max-width:767px) {font-size: 20px;line-height:30px;}
        }   
    }
}
.blog-page{
    background-image: url(../Assets/img/Bg/dashboard-bg.png);
    background-repeat: no-repeat;background-size: aut;background-position: top center;
    min-height: 100vh;padding-top: 180px;position: relative;
    padding-bottom: 20px;text-align: center;
}
.blog-post-detail {
    .card-title{@include Font-chakra}
    p{color: $white;
        a{color: $yellow;}
        b,strong{@include Font-chakra;font-size: 22px;}
    }
}
// Entires Panel
.entries-pnl{
    background-color: #1C202E;
    max-width: 780px;width: 100%;margin: 0 auto;border-radius: 20px;
    // Entries Head
    .entries-head{
        border-radius: 20px 20px 0 0 ;
        padding: 20px 0px 10px;
        ul{
            display: flex;justify-content: center;align-items: center;margin:0;
            li {margin: 0;position: relative;width: 33.33333%;border-bottom:4px solid;
                padding-bottom: 15px;border-color: #2589C5;
                &:first-child{border-color: #E66C69;}
                &:last-child{border-color: #6B5B8C;}
                &:not(:first-child):before{
                    position: absolute;content: '';
                    left: 0px;top: 0px;bottom: 10px;width: 1px;
                    background-color: #434652;
                }
                h4,p{margin: 0;font-weight: 600;}
                h4{
                    @media (max-width:767px) {font-size: 20px;}
                }
                p{
                    @media (max-width:767px) {font-size: 16px;}
                }
            }
        }
    }
    // Entries Head
    
    // Entries Body
    .entries-body{padding: 0px 20px;
        p{font-size: 18px;
            span{opacity: 0.2;font-size: 20px;}
            @media (max-width:767px) {font-size: 16px;line-height:18px;}
        }
        .purple-link{color: $color;font-size: 18px;text-decoration: underline !important;line-height: unset;
            span{
                display: inline-flex;justify-content: center;align-items: center;border-radius: 3px;
                width: 18px;height: 18px;font-weight: 400 !important;color: $white;font-size: 16px;
                background: linear-gradient(to bottom,  rgba(255,255,255,1) 0%,rgba(99,127,153,1) 41%,rgba(99,127,153,1) 100%);
            }
            &:hover{color: $yellow;}
        }
        .follow-entry-list{
            li{display: inline-block;width: 100%;
                .center-txt,a{
                    width: 100%;display: flex;justify-content: space-between;border-radius: 3px;padding: 5px;
                    border: 1px solid #434652;background-color: #2E313E;margin-bottom: 5px;min-height: 44px;
                }
                .center-txt{justify-content: center;line-height: 30px;}
                p{margin: 0;font-size: 16px;font-weight:600;text-align: left;
                    @media (max-width:767px) {font-size: 16px;}
                    @media (max-width:575px) {font-size: 12px;}
                    img{width: 30px;margin-right: 20px;
                        @media (max-width:767px) {margin-right: 10px;width:30px;}
                        @media (max-width:575px) {margin-right: 3px;width:20px;}
                    }
                }
                span{
                    background-color: #2E313E;border: 1px solid #434652;border-radius: 3px;
                    width: 65px;line-height: 30px;color: $white;font-weight:600;
                }
                &:hover{
                    p:not(.center-txt){color: $yellow;}
                }
            }
        }
    }
    // Entries Body

    // Entries Foot
    .entries-foot{
        border-radius: 0 0 20px 20px;border: 1px solid #434652;background-color: #2E313E;padding: 10px 15px;
        @media(max-width:575px){padding: 10px 5px;}
        ul{
            display: flex;justify-content: center;align-items: center;margin:0;
            li {margin: 0 10px;position: relative;font-size: 14px;color: $white;
                @media(max-width:575px){font-size: 13px;margin:0 5px;}
                &:not(:first-child):before{
                    position: absolute;content: '';
                    left: -10px;top: 3px;bottom: 0;width: 1px;background-color: #434652;
                    @media(max-width:575px){left: -5px;}
                }
                a{font-size: 14px;color: $white;
                    @media(max-width:575px){font-size: 13px;}
                    &:hover{color: $yellow;}
                }
            }
        }
    }
    // Entries Foot
}
// Entires Panel

@media(max-width:767px){
.m-w-100{width: 100%;}
}
