// xl={{ span: 6, offset: 3 }}
h1{font-size: 55px;font-weight: 700;color: $white;@include Font-chakra;
    // span{color: $color;font-weight: 700;}
    @media(max-width:1400px) {font-size: 40px;}
    @media(max-width:1200px) {font-size: 38px;}
    @media(max-width:991px) {font-size: 64px;}
    @media(max-width:767px) {font-size: 40px;}
    @media(max-width:575px) {font-size: 30px;}
}
h2{
    font-size: 48px;font-weight: 800;color: $white;@include Font-chakra;
    @media(max-width:1400px){font-size: 35px;}
    @media(max-width:1200px){font-size: 30px;}
    @media(max-width:991px){font-size: 48px;}
    @media(max-width:767px){font-size: 30px;}
    @media(max-width:575px){font-size: 22px;}  
}
h3{font-size: 30px;font-weight: 700;color:$white;@include Font-outfit;
    @media(max-width:1400px){font-size: 28px;}
    @media(max-width:1200px){font-size: 24px;}
    @media(max-width:767px){font-size: 22px;}
    @media(max-width:575px){font-size: 20px;}
}
h4{font-size: 22px;font-weight: 400;color:$white;@include Font-outfit;line-height: 32px;
    @media(max-width:1400px){font-size: 22px;line-height: 28px;}
    @media(max-width:1200px){font-size: 20px;line-height: 26px;}
    @media(max-width:991px){font-size: 26px;line-height: 32px;}
    @media(max-width:767px){font-size: 20px;line-height: 26px;}
}
h5{font-size: 20px;font-weight: 400;line-height: 28px;
    @media(max-width:1400px){font-size: 18px;}
    @media(max-width:991px){font-size: 22px;}
    @media(max-width:767px){font-size: 18px;}
}
h6{font-size: 16px;font-weight: 700;color:$white;}
.admin-panel h6{font-size: 18px;font-weight: 800;}
p{font-size: 16px;font-weight: 400;color:$white;}
.f-18{font-size: 18px !important;}
.f-26{font-size: 26px !important;}
.f-20{font-size: 20px;}
.letter-spacing{letter-spacing: 3px;}
.letter-spacing-1{letter-spacing: 1px;}
.font-weight-medium{font-weight: 500 !important;}