*{margin: 0;padding: 0;image-rendering: -o-crisp-edges;image-rendering: -webkit-optimize-contrast;-ms-interpolation-mode: nearest-neighbor}
body,html,main{-webkit-font-smoothing: antialiased}
a,a:focus,button,button:focus{outline: 0 !important;box-shadow: none;}
a{display: inline-block;text-decoration: none !important;}
.bg-layer{opacity: 1;background-size: cover;background-position: center center;}
.bg-layer{position: absolute;top: 0;left: 0;}
.bg-layer,iframe,video,source{height: 100%;width: 100%}
img,picture,svg{max-width: 100%;}
.full-div{display: inline-block;width: 100%;}
.half-div{display: inline-block;width: 50%;float: left;}
.txt-right,.text-right{text-align: right;}
.txt-center,.txt-centr,.text-center{text-align: center;}
.txt-left,.text-left{text-align: left;}
.z-ind{position: relative;z-index: 4;}
*{
  scrollbar-color: $color #FFD163;
}
.mobile-text-center{
  @media(max-width:767px){text-align: center !important;}
}
.mv-p-0{
  @media(max-width:991px){padding: 0 !important;}
}
section{padding-top: 100px;padding-bottom: 100px;position: relative;
  @media(max-width:1600px){padding-top: 80px;padding-bottom:80px;}
  @media(max-width:1200px){padding-top: 50px;padding-bottom:50px;}
  @media(max-width:767px){padding-top: 30px;padding-bottom:30px;}
}
.flex-div{display: flex;justify-content: space-between;width:100%;align-items: center;}
.max-w-100{max-width: 100% !important;}
.flex-div-md{display: flex;justify-content: space-between;width:100%;
  @media(max-width:991px){display: inline-block;}
}
.inline-flex-div-md{display: inline-flex;justify-content: space-between;
  @media(max-width:991px){display: inline-block;text-align:center;}
}
.flex-md{
  display: flex;
  @media(max-width:991px){display: inline-block;text-align:center;}
}
.flex-div-sm{display: flex;justify-content: space-between;width:100%;align-items: center;
  @media(max-width:767px){display: inline-block;text-align:center;}
}
.flex-div-xs{display: flex;justify-content: space-between;width:100%;
  @media(max-width:575px){display: inline-block;text-align:center;}
}
body,html{min-height: 100%;height: auto;}
img{max-width: 100%;}
button,div,img,h1,h2,h3,h4,h5,h6,p,a,li,span{@include trans();}
ul{padding-left: 0;}
.pd-r-0{padding-right: 0;}
.pd-l-0{padding-left: 0;}
body{overflow-x: hidden !important;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;}
input:focus,button:focus{box-shadow: none !important;}

.mobile-view{display: none;}
.table-mobile-view{display: none;}
@media(max-width:991px){
  .table-web-view{display: none;}
  .table-mobile-view{display: inline-block;}
}
.text-right-web{text-align: right;}
.web-view-md{display: flex;align-items: center;}
@media(max-width:767px){
    .web-view{display: none;}
    .mobile-view{display: block;}
    .mmt-3{margin-top: 20px;}
    .m-j-c{justify-content: space-between;}
    .mb-text-left{text-align: left;}   
    .text-right-web{text-align: left;}
}
.mobile-view-md{display: none;}
@media(max-width:991px){
    .web-view-md{display: none !important;}
    .mobile-view-md{display: block;}
}
@media(min-width:992px){
  .tablet-view{display: none !important;}
}
@media(min-width:1360px){.container{max-width: 1360px;}}
/**** divider ***/
.spacer-5{width: 100%;height: 5px;display: block;clear: both;}
.spacer-10{width: 100%;height: 10px;display: block;clear: both;}
.spacer-20{width: 100%;height: 20px;display: block;clear: both;}
.spacer-30{width: 100%;height: 30px;display: block;clear: both;}
.spacer-40{width: 100%;height: 40px;display: block;clear: both;}
.spacer-50{width: 100%;height: 50px;display: block;clear: both;}
.spacer-60{width: 100%;height: 60px;display: block;clear: both;}
.hidden{display: none;}
hr{display: block;clear: both;border-top: solid 2px $blue;margin: 5px 0 10px;}

.flex-contr{display: flex;justify-content: space-between;width:100%;
  @media (max-width:1200px){
    &.lg{display: inline-block;
      .w-5,.w-7{width: calc(100% - 30px);}
    }
  }
  @media (max-width:767px){display: inline-block;width:100%;}
  .w-7{width: calc(58.333333% - 15px);margin-left: 15px;margin-right: 15px;
    @media (max-width:1200px){
      width: calc(50% - 30px);
    }
    @media (max-width:767px){
      width: calc(100% - 30px);
    }
  }
  .w-5{width: calc(41.666667% - 15px);margin-left: 15px;margin-right: 15px;
    @media (max-width:1200px){
      width: calc(50% - 30px);
    }
    @media (max-width:767px){
      width: calc(100% - 30px);
    }
  }
  .w-8{width: calc(66.666667% - 30px);margin-left: 15px;margin-right: 15px;
    @media (max-width:1200px){
      width: calc(100% - 30px);
    }
  }
  .w-4{width: calc(33.33333% - 30px);margin-left: 15px;margin-right: 15px;
    @media (max-width:1200px){
      width: calc(100% - 30px);
    }
  }
  .w-6{width: calc(50% - 30px);margin-left: 15px;margin-right: 15px;
    @media (max-width:1200px){
      width: calc(100% - 30px);
    }
  }
}
// ::placeholder{color: $lightgrey;}
// :-ms-input-placeholder {color: $lightgrey;}
// ::-ms-input-placeholder {color: $lightgrey;}
// input[type="checkbox"]{accent-color: $color;}
body{font-family: "Titillium Web", sans-serif;font-weight: 400;font-size: 16px;background-color: $black;color: $white;}
.Titillium{font-family: "Titillium Web", sans-serif !important;}
.chakra{font-family: "Chakra Petch", sans-serif !important;}

.outfit{font-family: "Outfit", sans-serif !important;}
ul{list-style: none;}
.pagenotfound{width: 100%;height: calc(100vh - 361px);display: flex;justify-content: center;align-items: center;min-height:350px;text-align: center;
  padding: 0 15px;
  h1{color: $purple;margin-top: 150px;}
}
p.border-text{
  font-size: 20px;color: $white;padding-left: 20px;
  border-left: 2px solid $color;margin-bottom:50px;
  &.clr{color: $purple;}
}
.img-sp{position: relative;top: -2px;}
.zindex{z-index: 5;position: relative;}
.gradient-color{
  background:  linear-gradient(to right,  rgba(255,209,99,1) 0%,rgba(252,237,200,1) 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.lightgrey-color{color: $bordercolor !important;}
.light-yellow{color: $yellow !important;}
.yellow-color{color: $yellow !important;}
.lighter-yellow{color: $lighteryellow !important;}
.yellow-bg{background-color: $yellow !important;}
.purple-color{color: $purple !important;}
.purple-bg{background-color: $purple !important;}
.lightpurple-color{color: $lightpurple !important;}
.white-text{color: $white;}
.textgradient{@include textgradient;}
.darkbluecolor{color: $darkblue;
  &:hover{color: $color;}
}
.color{color: $color !important;}
.color-bg{background-color: $color !important;}
.greencolor{color: $greencolor !important;}
.gradientcolor{background: linear-gradient(to right,  rgba(219,219,2,1) 0%,rgba(135,135,2,1) 100%); }
.redcolor{color: $redcolor !important;}
.whitecolor{color: $white !important;}
.dark-color{color: $dark;}
.blue-color{color: $blue !important;}
a.text-decor{text-decoration: underline !important;}
a.text-none{text-decoration: none !important;}
.text-left-fix{text-align: left !important;}
.btn-list{display: flex;margin:0;
  &.justify-center{justify-content: center;}
  li{display: inline-block;margin:0 7.5px 15px;}
  @media(max-width:767px){
    flex-direction: column;
    li{
        margin-left:0;margin-right:0;width: 100%;
        a,button{width: 100%;}
    }
}
}
// Accordian
.accordion{text-align: left;padding-top:10px;margin-top:20px;
  .accordion-item{margin-bottom: 5px;
    .accordion-header{margin: 0;
      button{background-color: transparent;border: none;color: $white;font-size: 22px;font-weight: 700;
        padding:15px 45px 15px 20px;width:100%;text-align: left;@include Font-chakra;
        background: rgba(140, 92, 92, 0.13);border-radius: 12px 12px 0 0;
        backdrop-filter: blur(40px);margin-bottom:0px;
        @media(max-width:767px){font-size: 20px;padding: 20px 35px 20px 10px;};
        &:hover{color: $yellow;}
        span{width: 22px;height: 22px;display: inline-block;position: absolute;margin-right:20px;transform: rotate(45deg);right:0px;
          &:before{position: absolute;content: "";height: 2px;background-color: $yellow;top: 10px;left: 0;right: 0;@include trans;}
          &:after{position: absolute;content: "";width: 2px;background-color: $yellow;top: 0px;left: 10px;bottom: 0;@include trans;}
        }
        &.collapsed{
          border-radius: 12px;
          span{transform: rotate(0deg);
            &:after,
            &:before{background-color: $yellow;}
          }
        }
      }
    }
  }
  .accordion-body{padding: 10px 20px;color: $lighteryellow;font-size: 20px;@include Font-outfit;
    background: rgba(140, 92, 92, 0.13);backdrop-filter: blur(40px);margin-bottom:10px;border-radius: 0 0 12px 12px;
    p{color: $lighteryellow;font-size: 20px;@include Font-outfit;}
  }
}
// Accordian

// Pagination
.pagination{
  margin: 0;
  @media (max-width:767px) {
  justify-content: center;
  margin: 30px auto 0;    
  }
  .page-item{
    .page-link{background-color: transparent;border: none;
      color: $white;font-size: 16px;padding: 2px 2px;font-weight:300;
      margin: 0 5px;
      span{color: $white;}
      &:hover{color: $color;
        span{color: $color;}
      }
    }
    &.active{
      background-color: #313131;color: $white;
      .page-link{color: $white;
        .visually-hidden{display: none;}
      }
    }
    &:first-child{
      .page-link{position: relative;
        span:first-child{font-size: 50px;position: absolute;left: -10px;top: -3px;line-height: 16px;}
      }
    }
    &:last-child{
      .page-link{position: relative;
        span:first-child{font-size: 50px;position: absolute;right: -10px;top: -3px;line-height: 16px;}
      }
    }
  }
}
// Pagination
.referal-container{
  ul li,
  p{color: $bordercolor;}
  h4{@include Font-chakra;}
  .dot-list li::before {background-color: $bordercolor;}
  .referal-pnl{
    display: inline-flex;padding:10px;justify-content: space-between;
    background-color: $white;border-radius: 5px;min-width: 450px;margin-bottom:5px;
    p{color: $black;}
  }
  .border-round{border-radius: 35px;}
}


// Search Panel
.search-pnl{
  display: flex;justify-content: space-between;border-radius: 5px;
  align-items: center;padding: 0px 10px;background-color: $white;width:300px;margin-left:15px;
  button{background-color: transparent;border: none;color: $black;
    width: 20px;height:20px;display: inline-flex;justify-content: center;align-items: center;font-size:18px;
    &:hover{background-color: $color;}
  }
  input{background-color: transparent !important;border: none;color: $black;}
}
// Search Panel

// Tabs
.nav-tabs{border: none;margin-bottom:20px;
  .nav-item{width: 33.33333%;padding:0 10px;display:flex;
    @media(max-width:575px){padding: 0 3px;}
    button{width: 100%;border: none;border-radius: 5px;
      background-color: transparent;color: $white;font-weight: 600;
      text-align: center;font-size: 16px;border:none;
      @media(max-width:991px){font-size: 14px;}
      @media(max-width:767px){font-size: 12px;}
      &.active,
      &:hover{
        background-color: rgba(255,255,255,0.30);
        color: $white;
      }
      &.active{font-weight: 700;}
      @media(max-width:1400px){padding: 0.5rem;}
    }
  }
}
.admin-panel{
  .tab-content{
    @include post;padding: 30px;background-color: rgba(6,2,30,1);margin-bottom: 30px;position: relative;
      @media(max-width:767px){padding: 30px 15px;}
  }
}
// Tabs


// Blog post card
.blog-post-card {
  background: linear-gradient(to bottom,  rgba(7,15,43,0) 0%,rgba(84,5,5,1) 100%);
  border-bottom-left-radius: "15px";
  border-bottom-right-radius: "15px";
  border-top-left-radius: "10px";
  border-top-right-radius: "10px";
  border: "1px solid rgba(20, 5, 116, 0.7)";
  padding: "5px";
  padding-bottom: "5px";
  backdrop-filter:
    "blur(10px)";
  box-shadow:
    "0 8px 32px 0 rgba(31, 38, 135, 0.37)";
  backdrop-filter: "blur(5px) contrast(0.8)";
  .card-body{border-radius: 15px;}
  .reg-btn.trans{
    &:hover{color: $black !important;}
  }
}

.blog-post-card:hover {
  animation: glow 1.5s ease;
}


@keyframes glow {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.slick-prev, .slick-next{
  width: 18px !important;height: 22px !important;
  background-image: url(../Assets/img/Icons/carosle-arrow-left.png) !important;
  &::before{
      display: none;
  }
  @media(max-width:991px){display: none !important;}
}
.slick-next{
  background-image: url(../Assets/img/Icons/carosle-arrow-right.png) !important;
}
.slick-dots{bottom: 0 !important;z-index: 5;
  li{width: 10px !important;height:10px !important;
    button{
      background: rgba(255,255,255,0.5) !important;
      width: 10px !important;height:10px !important;border-radius:50%;
      &:before{display: none;}
    }
    &.slick-active{
      button{  background: rgba(255,255,255,1) !important;}
    }
  } 
}























.profit-launch-post{
  position: relative;height: 100vh;width: 100%;display: flex;justify-content: center;align-items: center;position: relative;
  &:after{
      position: absolute;content: "";
      left: 0;right: 0;top: 0;height: 25%;
      background: linear-gradient(180deg, #000000 30%, rgba(7, 0, 6, 0) 100%);
  }
  &:before{
      position: absolute;content: "";
      left: 0;right: 0;bottom: 0;height: 25%;
      background: linear-gradient(360deg, #000000 30%, rgba(7, 0, 6, 0) 100%);
  }
  .profit-launch-post-inner{
      position: relative;width: 100%;height: 100%;
      &:after{
          position: absolute;content: "";
          left: 0;top: 0;bottom: 0;width: 250px;
          background: linear-gradient(to right,  rgba(0,0,0,1) 0%,rgba(0,0,0,0) 100%); 

      }
      &:before{
          position: absolute;content: "";
          right: 0;top: 0;bottom: 0;width: 250px;
          background: linear-gradient(to left,  rgba(0,0,0,1) 0%,rgba(0,0,0,0) 100%); 
      }   
  }
  @media(max-width:1400px){padding: 50px 70px;}
  @media(max-width:1200px){padding: 50px 60px;}
  @media(max-width:991px){padding: 50px 50px;}
  @media(max-width:767px){padding: 50px 15px 20px;flex-direction: column;}
  .bg-layer{border-radius: 20px;z-index: -1;opacity: 1;background-size: 100% auto;background-position: 10% center;
      background-repeat: no-repeat;
  }
  h2{position: relative;margin:0;font-size: 64px;
      @media(max-width:991px){font-size: 32px;}
      @media(max-width:767px){font-size: 24px;margin-bottom:30px;}
      @media(max-width:575px){font-size: 16px;}
      span{color: $white;-webkit-background-clip: #fff !important;background-clip: unset;-webkit-text-fill-color: unset !important;
          font-size: 150px;
      }
      &.bg-text{margin-right: 10px;
          &:before{
              position: absolute;content: "";left: -45px;top: -27px;width: 100px;height: 100px;z-index: 0;
              opacity: 0.7;background-image: url(../Assets/img/admin-icons/icon-bg-b.png);background-size: 100% 100%;
              @media(max-width:1400px){left: -29px;top: -20px;width: 80px;height: 80px;}
              @media(max-width:991px){left: -40px;top: -25px;width: 120px;height: 120px;}
              @media(max-width:767px){left: -29px;top: -15px;width: 80px;height: 80px;}
              @media(max-width:575px){left: -23px;top: -15px;opacity: 1;     width: 60px;height: 60px;}

          }
      }
  }
}
.color-red{color: red;}
.CircularProgressbar .CircularProgressbar-path {stroke: #c1c102 !important;}  
.center-allign{text-align: -webkit-center !important;}
.center-text-allign{text-align: center;width: 100%;}
.reg-btn-connect {padding: 11px 35px;
  /* background: linear-gradient(135deg, rgba(224, 224, 38, 0) 0%, #bdbd0b 100%); /
  / background-color: #DBDB02; */
  border: none;font-weight: 800;color: #000000;text-transform: uppercase;}
.bg-container{position: relative;
  .bgg-layer{
      position: absolute;
      left: 0;right: 0;top: 150px;bottom: 0;
      background-attachment: fixed;
      background-size: cover;opacity: 0.1;
      background-position: center top;
  }
}
