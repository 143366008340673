input:-internal-autofill-selected {
    background-color: transparent !important;
}
textarea,
select,
input{
    &:focus{border-color: $color !important;}
}
label{font-size: 14px;color: $white;font-weight: 400;margin-left: 15px;margin-bottom: 0;}
// Log Modal Panel
.log-panel{
    form{text-align: left;
        label{font-size: 14px;color: $white;font-weight: 400;margin-left: 15px;margin-bottom: 0;}
        select{
            background-color: $white;border-radius: 5px;height: 42px;
            border-color: $white;color:$black;
            &.token-select{
                // background-image: url(../Assets/img/Icons/icon-subtract-1.png) !important;
                // background-repeat: no-repeat;background-position: 20px center;padding-left: 50px;
                option{padding-left: 0;}
                &:focus{
                    // background-image: url(../Assets/img/Icons/icon-subtract-2.png) !important;
                    // background-repeat: no-repeat;background-position: 20px center;
                }
            }
            &:focus{background-color: $white;border-color: $bordercolor;box-shadow: none !important;}
        }
        input{background-color: $white;border-radius: 5px;height: 42px;border-color: $bordercolor;color:$black;
            // &.input-email{
            //     background-image: url(../Assets/img/Icons/icon-envelpoe-1.png) !important;
            //     background-repeat: no-repeat;background-position: 20px center;padding-left: 50px;
            // }
            // &.input-password{
            //     background-image: url(../Assets/img/Icons/icon-lock.png) !important;
            //     background-repeat: no-repeat;background-position: 20px center;padding-left: 50px;
            // }
            // &.input-name{
            //     background-image: url(../Assets/img/Icons/icon-user-1.png) !important;
            //     background-repeat: no-repeat;background-position: 20px center;padding-left: 50px;    
            // }
            // &.input-dollar{
            //     background-image: url(../Assets/img/Icons/icon-dollar-1.png) !important;
            //     background-repeat: no-repeat;background-position: 20px center;padding-left: 50px;        
            // }
            &:focus-visible,
            &:focus{background-color: $white;border-color: $bordercolor;color:$black;
                // &.input-email{
                //     background-image: url(../Assets/img/Icons/icon-envelpoe-2.png) !important;
                //     background-repeat: no-repeat;color: $black;background-position: 20px center;
                // }
                // &.input-password{
                //     background-image: url(../Assets/img/Icons/icon-lock-2.png) !important;
                //     background-repeat: no-repeat;color: $black;background-position: 20px center;
                // }
                // &.input-name{
                //     background-image: url(../Assets/img/Icons/icon-user-2.png) !important;
                //     background-repeat: no-repeat;color: $black;background-position: 20px center;
                // }    
                // &.input-dollar{
                //     background-image: url(../Assets/img/Icons/icon-dollar-2.png) !important;
                //     background-repeat: no-repeat;color: $black;background-position: 20px center;
                // }
            }
        }
        input[type="checkbox"]{accent-color: $yellow;width: 15px;height: 15px;color: $white;}
        .form-check{
            label{font-size: 16px;margin: 0;}
        }
    }
}


// Log Modal Panel

// DashBoard Modal Form
.admin-panel,
.dashboard-modal{
    label{font-size: 14px;color: $white;font-weight: 500;margin-left: 15px;margin-bottom: 0;
        font-style: italic;
        &.lightpurple-color{color: $lightpurple !important;}
    }
    select{
        background-color: $white;border-radius: 5px;height: 42px;
        border-color: $bordercolor;color:$black;
        &.token-select{
            // background-image: url(../Assets/img/Icons/icon-subtract-1.png);
            // background-repeat: no-repeat;background-position: 20px center;padding-left: 50px;
            option{padding-left: 0;}
            &:focus{
                // background-image: url(../Assets/img/Icons/icon-subtract-2.png);background-repeat: no-repeat;background-position: 20px center;
            }
        }
        &:focus{background-color: $white;border-color: $bordercolor;box-shadow: none !important;}
    }
    input{background-color: white;border-radius: 5px;height: 42px;
        border-color: $bordercolor;color:$black;
        // &.input-email{
        //     background-image: url(../Assets/img/admin-icons/icon-envelpoe-1.png);
        //     background-repeat: no-repeat;background-position: 20px center;padding-left: 50px;
        // }
        // &.input-promocode{
        //     background-image: url(../Assets/img/admin-icons/icon-percentage-1.png);
        //     background-repeat: no-repeat;background-position: 20px center;padding-left: 50px;    
        // }
        // &.input-name{
        //     background-image: url(../Assets/img/admin-icons/icon-user-3.png);
        //     background-repeat: no-repeat;background-position: 20px center;padding-left: 50px;    
        // }
        &:focus-visible,
        &:focus{background-color: $white;border-color: $bordercolor;color:$black;
            // &.input-email{
            //     background-image: url(../Assets/img/admin-icons/icon-envelpoe-2.png);
            //     background-repeat: no-repeat;color: $black;background-position: 20px center;
            // }
            // &.input-promocode{
            //      background-image: url(../Assets/img/admin-icons/icon-percentage-2.png);
            //     background-repeat: no-repeat;background-position: 20px center;
            // }
            
            // &.input-name{
            //     background-image: url(../Assets/img/admin-icons/icon-user-4.png);
            //     background-repeat: no-repeat;color: $black;background-position: 20px center;
            // }                
        }
    }
    input[type="radio"]{accent-color: $dark;width: 18px;height: 18px;}
    input[type="checkbox"]{accent-color: $color;width: 15px;height: 15px;}
    .form-check{
        label{font-size: 16px;margin: 0;margin-left: 5px;font-weight:400;font-style:normal;color:$white;
            a{color: $blue;text-decoration: underline !important;
                &:hover{color: $color;}
            }
        }
    }
}
// DashBoard Modal Form

.log-i-cntnr{
    position: relative;
    svg,i{position: absolute;right: 15px;top: 13px;z-index: 5;}
    input{padding-right: 35px;}
}

.log-i-cntnr {
    position: relative;
  }
  
  .log-i-cntnr input {
    padding-right: 35px;
  }
  
  .log-i-cntnr .FaEye {
    position: absolute;
    right: 15px;
    top: 13px;
    z-index: 5;
    color: white; /* Default color */
  }
  .log-i-cntnr .FaFlag {
    position: absolute;
    right: 0px;
    top: 0px;
    left : 15px;
    font-size: 30px;

    z-index: 5;
    color: white; /* Default color */
  }
  
  .log-i-cntnr input:focus + .FaEye {
    color: black; /* Color when input is focused */
  }


  
  .log-i-cntnr .FaEyeSecond {
    position: absolute;
    right: 15px;
    top: 13px;
    z-index: 5;
    color: black; /* Default color */
  }
  
  .log-i-cntnr input:focus + .FaEyeSecond {
    color: black; /* Color when input is focused */
  }


  .NavSelect{

 

  

.css-1dimb5e-singleValue {

    color:white !important
}
.css-166bipr-Input
{

  color: white !important;
}

  }

.form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1;
    color: black !important;
}  
.react-tel-input .form-control{
    background-color: transparent !important;
    width: 100% !important;
}

.react-international-phone-input-container .react-international-phone-input{
    width: 100% !important;
    border-top-right-radius: 30px !important;
    border-bottom-right-radius: 30px !important;
    // height: 35px !important;
    border-color : $bordercolor !important;
    color:$white !important;


}


.css-1mtmiup{
    background: transparent !important;
    color: white !important;
    font-weight: 700 !important;
}

.css-1g5k5ll{
    background: transparent !important;
    outline: none !important;
    border: none  !important;
    padding: 5px 5px !important;
}
.loader-circle{
    text-align: -webkit-center;
    width: 100%;
}
.addressTo{
    word-break: break-all;
}

.bg-layer__video {
    opacity: 0.6;
    width: 100%;
    height: 100%; /* Maintain aspect ratio */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: cover;
    z-index: -1;
  }

  .justifycenter{
    text-align: -webkit-center !important;
  }

 

  .displayNone{
    display: none;
  }

  .otpinput{
    justify-content: center;
    display: flex;
    margin-bottom: 20px;
    input{
        width: 15% !important;
        height: 50px !important;
    }
  }

  .hero_icons
{

    display: flex !important;
    flex-direction: row !important;
    justify-content: space-evenly !important;
    margin-top: 20px !important;
    margin-bottom: 10px !important;

    // style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', marginTop: '20px',marginBottom:'10px'
}



/* Apply styles for autofill */
/* Apply styles for autofill */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
  color : black !important;
  -webkit-text-fill-color: black !important;
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
  -webkit-background-clip: text !important;
  background-clip: text !important;
}

.react-international-phone-input-container{
    background-color: $white;border-radius: 5px;
    .react-international-phone-country-selector{border-radius: 5px 0 0 5px !important;}
    input{border-radius: 0 5px 5px 0 !important;}
}

.checkbox-inline{
    display: none !important;
}