.modal-content{
    background-color: transparent;
    @include boxshadow;
    background-image: url(../Assets/img/Bg/bg-popup.png);
    background-size: 100% 100%;
    border-radius: 20px;
    &::before{
        position: absolute;content: "";z-index: -1;border-radius: 20px;
        left: -1px;right: -1px;bottom: -1px;top: -1px;
        background: linear-gradient(to right,  rgba(255,255,255,0.7) 0%,rgba(255,255,255,0.4) 50%,rgba(255,255,255,0.7) 100%); 
    }
}

// Close Button
.close-btn{
    padding: 0;font-size: 26px;border: none;position: absolute;right: 20px;top: 10px;
    background-color: transparent !important;color: $white;
    &:hover{color: $color;}
}
// Close Button

// Bottom Line
.bottom-line{
    position: absolute;left: 0;right: 0;text-align: center;z-index: 999;bottom: 0;border-radius: 0 0 20px 20px;
    background: linear-gradient(to right,  rgba(158,142,244,1) 0%,rgba(47,24,168,1) 98%);
    p{margin: 0;color: $white;font-weight: 600; margin:0 !important;font-size: 16px;
        text-transform: uppercase;
        span{font-weight: 800;color: $yellow;font-size: 16px;}        
    }
}
// Bottom Line

// Text List
.text-list{display: inline-block;margin:20px 0 30px;
    @media(max-width:991px){width: 100%;margin-bottom:10px;}
    li{display: inline-block;margin: 0 25px;float:left;
        @media(max-width:991px){float: none;width:100%;margin:0 ;margin-bottom:5px;
        display: flex;justify-content: space-between;}
        p{font-weight: 400;margin: 0;
            b{color: $yellow;}
        }
    }
}
// Text List

// Log Modal Panel
.log-panel{
    padding: 80px 50px 20px;text-align: center;
    @media (min-width: 576px) {
        .modal-dialog {
            max-width: 600px;
        }
    }
    &.sign-up{
        @media(max-width:991px){padding: 70px 15px 40px;}
    }
    @media(max-width:767px){padding: 70px 15px 15px;}
    .reg-btn{max-width: 100%;min-width: 200px;
        @media(max-width:767px){width: 100%;}
    }
    h4{font-weight: 700;}
    p{font-size: 20px;margin-bottom: 30px;color:$color;@include Font-outfit;
        &.Titillium{@include Font-titllium;font-weight: 400;}
        &.white-text{color: $white;}
        a{color: $purple;text-decoration: underline !important;
            &:hover{color: $yellow;}
        }
    }
    span{font-size: 14px;color: $white;}
    h6{font-size: 18px;margin-top: 10px;font-weight: 400;}
    .btn-list{margin: 0;margin-top:40px;
        li{margin-bottom: 0;
            a{font-size: 14px;color: $white;text-decoration: underline !important;
                &:hover{color: $yellow;}
            }
        }
    }
}
// Log Modal Panel
.react-international-phone-input-container .react-international-phone-country-selector-button{border-radius: 5px !important;
    background-color: transparent !important;border-color: transparent;
    padding: 0 10px 0 20px;height: 42px;color: $black !important;
}
.react-international-phone-input-container .react-international-phone-input{
    border:none;border-radius: 5px !important;height: 42px;border:none !important;
}

.react-international-phone-input-container .react-international-phone-input{background-color: transparent !important;color: $black !important;}
.react-international-phone-country-selector-dropdown__list-item{color: $black !important;
    span{color: $black !important;}
}
// DashBoard Modal
.dashboard-modal{padding: 50px;
    @media(max-width:767px){
        padding: 50px 15px;
        .reg-btn{padding: 11px 20px;}
    }
    p{font-weight: 500;}
}
// DashBoard Modal

.modal{
    padding-left: 0;
}