
// Navbar
.navbar{
    position: fixed;left: 0;right: 0;top: 0;z-index: 99;padding: 10px 50px;
    @media(min-width:992px){
        background-color: rgba(20,20,20,0.15);
        backdrop-filter: blur(10px);
    }
    @media(max-width:1400px){padding: 10px 30px;}
    .navbar-brand{max-width: 170px;}
    @media(max-width:1200px) and (min-width:992px){padding-left: 0;padding-right:0;
        .navbar-brand{max-width: 200px;}
    }
    @media(max-width:991px){padding-top: 15px;background-color: $black;
        background-image: linear-gradient(to bottom, rgba(7, 15, 43, 0) 0%, rgb(84, 5, 5) 100%);
        .navbar-nav{height: calc(100vh - 95px);display: flex;justify-content: center;align-content: center;padding-bottom: 133px;}
        .join-presale-btn{font-size: 20px;}
    }
    
    @media(max-width:575px){padding: 20px 15px 10px;
        .navbar-brand{max-width: 180px;}
    }
    .border-btn{
        border: 1px solid $white;border-radius: 25px;font-size: 18px;font-weight: 600;
        text-transform: uppercase;color: $white;background-color: transparent;
        padding-left: 20px;padding-right: 20px;
        @media(max-width:1200px){padding-left: 10px;padding-right:10px;font-size: 16px;margin-left:10px;}
        @include Font-titllium;
        &:hover{
            background-color: $white !important;color: $black;border-color: $white;
            @include trans;
        }
    }
    .navbar-nav .nav-link{color: $white;text-transform: capitalize;margin: 0 25px;font-size:18px;
        @media(max-width:1400px){margin: 0 15px;}
        @media(max-width:1200px){margin: 0 10px;font-size:17px;}
        @media(max-width:991px){font-size: 20px;text-align:center;}
        
        &:focus{color: $white;}
        &.active,
        &:hover{
            color: $color;
        }
    }
    .mobile-view{
        @media(max-width:991px){
            position: absolute;left:30%;
            right:30%;bottom:15px;
            text-align: center;
            display: flex;
            flex-direction: column-reverse;
        }
        @media(max-width:767px){
            left:15px;right:15px;
        }
    }
    button,
    a:not(.reg-btn){color: $white; background-color: transparent !important;border: none;
        &:hover{color: $color;}
    }
    .reg-btn{margin-right: 20px;
        @media(max-width:1200px){margin-right: 0px;padding:7.5px 10px;width: 100%;}
        @media(max-width:991px){margin-right: 0px;padding:12px 35px;margin-top:15px;}
    }
        .web-view{ 
            a:not(.reg-btn){
                &:hover{color: $color;}
            }
            
        }
        .web-view-md{
            @media(max-width:1200px){
                .cnt-btn{padding: 0 !important;}
            }
        }
    // DropDown
    .dropdown-toggle{color: $white;font-weight:500px;text-transform: capitalize;
        span{width: 40px;height: 40px;display: inline-flex;justify-content: center;align-items: center;
            background-color: $color;border-radius: 50%;font-weight: 700;font-size: 16px;margin-right: 10px;
        }
    }
    .dropdown-menu{background-color: $black;min-width: 250px;padding:0;border-radius: 15px;
        left: unset;right:0;
        a{color: $white;padding:10px 15px;border-bottom:1px solid $dark;
            &:first-child{color: $white;border-radius: 15px 15px 0 0;background-color:$darkgrey !important;
                &:hover{color: $white;background-color: transparent !important;}    
            }
            &:last-child{border-radius: 0 0 15px 15px;border:none;}
            &:hover{
                background-color: $dark !important;
            }
            .img{display: inline-block;position: relative;width: 20px;height: 20px;margin-right:5px;
                img{
                    position: absolute;left: 0;top: 3px;@include trans;
                   
                }
            }
            &:hover,
            &.active{
                color: $white;
                .img{
                    img{
                      
                    }
                }
            }
        }
    }
    // DropDown
}
@media(min-width:992px){
    .navbar-expand-lg .navbar-collapse{justify-content: center;}
}
.navbar-toggler {
    border: none;
    padding: 0;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  
    span {
      display: inline-block;
      width: 100%;
      height: 2px;
      opacity: 0;
      background-image: none;
      background-color: $white;
      @include trans();
    }
  
    &:before {
      position: absolute;
      content: "";
      left: 0;
      right: 0;
      top: 13px;
      height: 2px;
      background-color: $white;
      @include trans();
      transform: rotate(-45deg);
    }
  
    &:after {
      position: absolute;
      content: "";
      left: 0;
      right: 0;
      bottom: 15px;
      height: 2px;
      background-color: $white;
      @include trans();
      transform: rotate(45deg);
    }
  
    &.collapsed {
      span {
        opacity: 1;
      }
  
      &:before {
        transform: rotate(0deg);
        top: 5px;
      }
  
      &:after {
        transform: rotate(0deg);
        bottom: 5px;
      }
    }
  
    &:hover {
  
      &:before,
      &:after,
      span {
        background-color: $white;
      }
    }
  }
// Navbar
// Dashboard Navbar
.dashboard-navbar.navbar{padding-top: 5px;position: absolute;
    @media(min-width:992px){
        background-color: transparent;
        backdrop-filter: blur(0px);
    }
    .navbar-collapse{background-color: transparent;}
     a.dropdown-toggle:not(.reg-btn){display: inline-flex;
        &::after{margin-top: 20px;margin-left: 5px;}
    }
    .mobile-view-flex{display: flex;align-items: center;}
    @media(max-width:1200px){
        .navbar-brand{margin-right: 10px;}  
    }
    @media(max-width:991px){
        padding-left: 0;padding-right:0;
        .navbar-collapse{text-align: left;}
        .navbar-nav{height: auto;}
        .navbar-brand{margin-left: 15px;max-width: 150px;}  
        .navbar-toggler{margin-right: 15px;}
        .navbar-nav .nav-link{text-align: left}
    }
    
    @media(max-width:575px){
        .mobile-view-flex .navbar-dropdown{display: none;}
    }
   
    .navbar-nav .nav-link{font-weight: 500;padding:10px 30px 12px;border-radius: 5px;
        @include Font-titllium;text-transform: uppercase;margin-top: 10px;
        position: relative;
        @media(max-width:1600px){padding:10px 25px 12px;font-size:16px;}
        @media(max-width:1400px){font-size:14px;}
        @media(max-width:1200px){padding:10px 20px 12px;font-size:13px;}
        @media(max-width:991px){padding:10px 20px 12px;font-size:18px;}
        @media(max-width:767px){font-size:16px;}
        &.active{color: $white;background-color: rgba(255,255,255,0.2) !important;
            .img{
                &:before{
                    opacity: 1 !important;
                }
            }
        }
        &:hover{color: $white;}
        .img{display: inline-block;position: relative;width: 20px;height: 20px;margin-right:5px;
            &::before{
                position: absolute;content: "";@include trans;
                left: -11px;right: -4px;bottom: -15px;top: -5px;
                background-image: url(../Assets/img/admin-icons/icon-log.png);
                background-size: 100% 100%;opacity: 0;
            }
            img{
                position: absolute;left: 0;top: 3px;@include trans;
                &:first-child{opacity: 1;}
                &:last-child{opacity: 0;}
            }
        }
        &:hover,
        &.active{
            .img{
                img{
                    &:first-child{opacity: 0;}
                    &:last-child{opacity: 1;}
                }
            }
        }
    }
}
@media(min-width:992px){
    .dashboard-navbar.navbar{padding-top: 5px;
        margin-top: 12px;
        .navbar-collapse{position: absolute;left: 0;right: 0;top: 65px;}
    }
}
// Dashboard Navbar



.react-tel-input .country-list .country-name {
    margin-right: 6px;
    color: black !important;
}

.react-international-phone-input-container .react-international-phone-input {
    overflow: visible;
    height: var(--react-international-phone-height, 36px);
    box-sizing: border-box;
    padding: 0 8px;
    border: 1px solid var(--react-international-phone-border-color, gainsboro);
    border-radius: var(--react-international-phone-border-radius, 4px);
    margin: 0;
    background-color: var(--react-international-phone-background-color, white);
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    color: var(--react-international-phone-text-color, #222);
    font-family: inherit;
    font-size: var(--react-international-phone-font-size, 13px);
    color: black !important;
}
.log-panel span {
    font-size: 14px;
    color: #ffffff;
    font-family: 'Quicksand', sans-serif;
    font-weight: 500;
    outline: none !important;
}

.navbar-dropdown{
    display: inline-block;
    button{font-size: 14px;}
    img{max-width: 25px;position: relative;top: -3px;margin-right: 5px;}
    .dropdown-menu{max-width: 100px;min-width: 100px;}
}

@media(max-width:991px){
    .mobile-view{
        .reg-btn-connect{display: flex;justify-content: center;padding: 10px 0;}
    }
}

@media(max-width:420px){
    .navbar .dropdown-toggle{font-size: 14px;}
    .dashboard-navbar.navbar .navbar-brand{margin-right: 0;margin-left: 10px;max-width: 140px;}
    .navbar .dropdown-toggle span{width: 28px;height: 28px;}  
    .navbar .dropdown-toggle{padding: .5rem .5rem;}
    .dashboard-navbar.navbar .navbar-toggler{margin-right: 10px;width: 24px;}
}