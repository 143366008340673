// Header
// header{
//     min-height: 100vh;display: flex;justify-content: center;align-items: center;
//     position: relative;padding-top:150px;padding-bottom:150px;
//     @media(max-width:767px){display: inline-block;width:100%;padding-bottom:30px;}
//     &:after{@include bottomshedow;}
//     h4{color: $white;margin-top: 30px;line-height: 32px;}
//     .accessbility-list{
//         position: absolute;left: 0;right: 0;bottom: 0;text-align: center;z-index: 3;
//         @media(max-width:767px){position: relative;}
//         li{display: inline-block;margin:0 20px;
//             @media(max-width:767px){width:50%;float:left;display:inline-block;margin:0;margin-top:20px;}
//             .img-pnl{height: 45px;display: flex;justify-content: center;align-items: center;}
//             span{display: inline-block;width: 100%;max-width: 130px;margin-top: 0px;
//                 font-size: 16px;color: $white;line-height: 20px;
//             }
//         }
//     }
// }
.dark-layer{@include post;padding: 30px 30px 30px;@include boxshadow;}

// Stage Post
.stage-post{
    display: flex;justify-content: space-between;
    @media(max-width:991px){align-items: center;
        .my-token-list{margin-bottom: 0;}
    }
    @media(max-width:767px){display: inline-block;width:100%;
        padding: 10px 10px 10px;
        .flex-div{text-align: left;}
    }  
    .img-pnl{width: 250px;
        @media(max-width:767px){margin: 0 auto;}
    }
    .txt-pnl{width: calc(100% - 250px);padding-left: 15px;
        @media(max-width:767px){width:100%;padding-left:0;}  
    }
    p{color: $white;
        @media(max-width:767px){font-size: 14px;}  
    }
   
}
// Stage Post
.btn-cntnr{display: flex;justify-content: center;align-items: center;}
.address-p{word-break: break-all;}
// Coin Button List
.coin-btn-list{width: 100%;display:inline-block;margin:0;
    li{width: 25%;margin: 0;float: left;padding: 0 2.5px;
        @media(max-width:767px){width: 50%;margin-bottom:10px;display:inline-block;}
        a{width: 100%;font-size: 14px;
            @media(max-width:1400px){padding: 11px 22px;}
            @media(max-width:1200px){padding: 11px 12px;font-size:12px;}
            @media(max-width:991px){padding: 11px 12px;font-size:14px;}
        }
    }
    &.three{margin-bottom: 20px;position: relative;top:-5px;
        li{width: 33.333333%;
            @media(max-width:767px){width: 50%;margin-bottom:10px;
                &:last-child{width: 100%}
            }
        }
    }
}
// Coin Button List

// Apply Code Panel
.apply-code-pnl{
    @include post;padding: 15px 30px 20px;margin-top:10px;text-align: left;
    @media(max-width:1400px){padding: 10px;}
    
    .apply-code-pnl-inner{display: flex;justify-content: space-between;
        form{width: calc(100% - 195.37px);}
        @media(max-width:1200px){
            form{width: calc(100% - 160px);}
        }
        @media(max-width:767px){
            form{width: calc(100% - 150px);}
        }
        @media(max-width:575px){
            form{width: calc(100% - 119.37px);}
        }
        
    }
    p{color: $white;margin-bottom: 5px;}
    a:not(.reg-btn){text-decoration: underline !important;color:$purple;
        &:hover{color: $yellow;}
    }
    .input-cntnr{width: 100%;position: relative;
        input{
            width: 100%;background-color: transparent;border: none;border-radius: 30px;
            height: 48px;color: $white;padding-left: 50px;
            background-image: url(../Assets/img/Icons/icon-percentage.png);
            background-repeat: no-repeat;
            background-position: 20px center;
        }
        &:before{
            position: absolute;content: "";left: 0;right: 0;bottom: 0;top: 0;
            border: 1px solid transparent;border-radius: 20px;z-index: -1;
            background: linear-gradient(to bottom,  rgba(56,63,105,1) 0%,rgba(21,31,96,0.02) 50%,rgba(22,32,96,0) 51%,rgba(56,63,105,1) 100%) border-box;  
            -webkit-mask:linear-gradient(#fff 0 0) padding-box,  linear-gradient(#fff 0 0);
            -webkit-mask-composite: xor; mask-composite: exclude; 
        }
    }
}
// Apply Code Panel

// Header

// Who We Are Panel

.video-pnl{
    width: 100%;position: relative;border-radius:15px;
    margin-top: 20px;
    box-shadow: 0 0 40px 40px rgba(255,255,255,0.1);
    iframe{border-radius: 15px;}
    span{position: absolute;left: calc(50% - 47px);top: calc(50% - 47px);z-index: 2;width: 93px;opacity: 0.9;
        &:hover{@include trans;opacity: 1;}
    }
}
// Who We Are


.buy-now-landing-panel{
    width: 100%;position: relative;border-radius: 15px;z-index: 10;
    margin: 30px auto;
    .buy-now-landing-panel{
        position: relative;background-color: $black;z-index: 2;width: 100%;
        height: 100%;padding: 40px 30px;text-align: center;
        @media(max-width:767px){padding: 40px 15px;}
    }
    &:before{
        position: absolute;z-index: -1;content: "";border-radius: 15px;left: -3px;right: -3px;bottom: -3px;top: -3px;
        background: linear-gradient(to right, rgb(244, 195, 25) 0%, rgb(214, 146, 25) 25%, rgb(248, 214, 72) 48%, rgb(239, 186, 55) 73%, rgb(229, 193, 73) 100%);
    }
    &:after{position: absolute;z-index: -1;content: "";border-radius: 15px;left: 0px;right: 0px;bottom: 0px;top: 0px;background-color: $black;}
    h3{font-family: "Chakra Petch", sans-serif !important;@include textgradient;}
    .progress-bar{height: 20px;
        .bar{@include hoverboxshadow;}
    }
    .toekn-price-dis{
        display: flex;align-items: center;
        justify-content: space-between;padding: 10px 15px;border-radius: 10px;
        border: 1px solid rgba(255, 255, 255, 0.24);
        @media(max-width:575px){padding: 10px 5px;}
        h5{color: $white;font-weight: 700;margin: 0;@include textgradient;
            @media(max-width:575px){line-height: 16px;}
        }
        h6{font-weight: 700;margin: 0;
            @media(max-width:575px){font-size: 14px;}
        }
    }
    .coin-card-list{display: flex;gap: 8px;margin-top: 20px;margin-bottom:10px;
        li{width: 33.3333%;
            a{
                display: flex;justify-content: center;align-items: center;position: relative;z-index: 2;border-radius:10px;gap:5px;
                padding: 15px 5px;color:$white;font-weight: 600;
                @media(max-width:575px){font-size: 12px;}
                img{width: 25px;
                    @media(max-width:575px){width: 20px;}
                }
                &:before{
                    position: absolute;content: "";z-index: -1;@include trans;left: 0;right: 0;bottom: 0;top: 0;border-radius:10px;
                    background: linear-gradient(to right, rgb(244, 195, 25) 0%, rgb(214, 146, 25) 25%, rgb(248, 214, 72) 48%, rgb(239, 186, 55) 73%, rgb(229, 193, 73) 100%);
                }
                &:after{
                    position: absolute;content: "";z-index: -1;left: 2px;right: 2px;bottom: 2px;top: 2px;@include trans;background: $black;border-radius:10px;
                    @media(max-width:575px){left: 1px;right:1px;bottom:1px;top:1px;}
                }
                &:hover{color:$color;}
            }
        }
    }
    form{text-align: left;
        label{padding-left: 0;color: $color;font-weight: 600;margin-bottom: 5px;@include textgradient;}
        input{
            background-color: transparent !important;border-color: rgba(255,255,255,0.5);height: 50px;border-radius:10px;
            &::placeholder{color: $white;font-weight: 700;}
        }
    }
    .border-input{
        border: 1px solid;border-color: rgba(255,255,255,0.5);border-radius:10px;
        display: flex;padding: 0 15px;margin-bottom: 20px;justify-content: space-between;align-items: center;
        img{max-width: 30px;}
        input{border: none !important;}
    }
    
}
.header{
    height: 100vh;display: flex;justify-content: center;align-items: center;position: relative;
    min-height: 1000px;
    @media(max-width:991px){
        min-height: 100vh;
        height: auto;
    }
    &::after{
        position: absolute;content: "";
        left: 0;bottom: 0;right: 0;height: 150px;z-index: 3;
        background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%);
    }
    &.landing-header{
        min-height: 600px;
        .bg-layer{opacity: 0.4;}
    }
    .bg-layer{
        background-position: center top;
        background-size: auto;
        background-repeat: no-repeat;
    }
    .text-pnl{max-width: 1200px;width: 100%;@include Font-outfit;
        @media(max-width:1400px){max-width: 800px;}
        @media(max-width:991px){padding-top: 100px;}
        h4{@include Font-titllium;font-weight: 300;margin-bottom: 15px;}
        h5{text-transform: uppercase;@include Font-titllium;font-weight: 700;
            @include textgradient;
        }
        &.video-container{
            max-width: 750px;
            padding-top: 100px;
        }
    }
    .btn-list{align-items: center;margin: 0 auto;position: relative;z-index: 5;
        gap: 10px;
        li{margin: 0;}
        @media(max-width:767px){
            flex-direction: column;
            li{
                margin-left:0;margin-right:0;width: 100%;
                a,button{width: 100%;}
            }
        }
    }
}
.text-gradient{
    background:  linear-gradient(to right,  rgba(255,209,99,1) 0%,rgba(252,237,200,1) 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

// Featured Post
.Featuredslide{
    text-align:left;margin-top:30px;padding: 0 15px;margin-bottom: 30px;
}
.featured-post{
    .img-pnl{height: 230px;display: flex;justify-content: center;align-items: center;
        img{max-height: 100%;border-radius: 15px;@include trans;}
        @media(max-width:767px){height: auto;}
    }
    .txt-pnl{padding-top: 10px;
        h5{color: $white;height:84px;overflow: hidden;margin-bottom:25px;font-weight:700;
            @media(max-width:767px){height: auto;}
        }
        h6{@include Font-outfit; img{display: inline-block;}}
    }
    &:hover{
        .img-pnl{
            img{@include hoverboxshadow;}
        }   
    }
}
// Featured Post

// Strategies Post
.strategies-post{
    display: inline-block;width: 100%;text-align: center;@include boxshadow;
    padding: 50px 15px 30px;position: relative;border-radius: 20px;margin-bottom: 50px;
    background-image: url(../Assets/img/logo-trans.png);background-repeat: no-repeat;
    @media(max-width:1400px){min-height: 428px;}
    @media(max-width:1200px){min-height: 476px;}
    @media(max-width:991px){min-height: 300px;}
    @media(max-width:767px){min-height: unset;max-width:345px;}
    &:before{
        position: absolute;content: "";left: 0;right: 0;bottom: 0;top: 0;
        border: 2px solid transparent;border-radius: 20px;z-index: -1;
        background: linear-gradient(to bottom,  rgba(56,63,105,1) 0%,rgba(21,31,96,0.02) 50%,rgba(22,32,96,0) 51%,rgba(56,63,105,1) 100%) border-box;  
        -webkit-mask:linear-gradient(#fff 0 0) padding-box,  linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor; mask-composite: exclude; 
    }
    .img-pnl{text-align: center;margin-bottom:30px;
        img{border-radius: 50%;width: 102px;height: 102px;}
    }
    .txt-pnl{
        h4{font-weight: 800;font-size: 20px;margin: 0;color: $white;}
        p{color: $purple;min-height: 120px;margin: 0;}
        span{display: inline-block;width: 40px;height: 1px;background-color: $color;}
    }
    &:hover{
        @include hoverboxshadow;
    }
}
// Strategies Post

// Crypto List
.tokenomics-pnl{position: relative;
    &::before {position: absolute;content: "";left: 0;right: 0;top: 0;height: 15%;background: linear-gradient(180deg, #000000 30%, rgba(7, 0, 6, 0) 100%);z-index: 1;}
    &::after {position: absolute;content: "";left: 0;right: 0;bottom: 0;height: 10%;background: linear-gradient(360deg, #000000 30%, rgba(7, 0, 6, 0) 100%);z-index: 1;}
    h2{position: relative;z-index: 5;}
    .bg-layer{
        background-repeat: no-repeat;
        background-size: 100% auto, auto;
        background-position: center center, 50% 25%;
        background-attachment: fixed;
    }
}
.crypto-supply-list{margin: 0;position: relative;z-index: 5;
    &:before{
        position: absolute;content: "";left: calc(33.33333% + 25px);top: 10px;width: 1px;height: 40px;
        background-color: rgba(255,255,255,0.25);
        @media(max-width:991px){display: none;}
    }
    &:after{
        position: absolute;content: "";right: calc(33.33333% + 25px);top: 10px;width: 1px;height: 40px;
        background-color: rgba(255,255,255,0.25);
        @media(max-width:991px){display: none;}
    }
    li{display: inline-block;width: 33.3333%;position: relative;padding:0 10px;
        @media(max-width:991px){width: 100%;
            &:not(:last-child){margin-bottom: 20px;}
        }
        img{max-width: 30px;margin-right: 5px;position: relative;top: -3px;}
        h3{color: $white;font-weight: 600;font-size: 30px;margin: 0;
            @include Font-chakra;
            @media(max-width:991px){font-size: 36px;}
            @media(max-width:767px){font-size: 26px;}
        }
        h6{text-transform: uppercase;font-size: 16px;font-weight: 600;@include textgradient;
            @media(max-width:767px){font-size: 14px;}
        }
    }
}
// Crypto List
.bg-shadow{@include gradient;}
.index-creator-panel{padding-top: 70px;padding-bottom: 100px;position: relative;
    h2{position: relative;z-index: 5;}
    .bg-layer{opacity: 0.2;}
    .img-pnl{position: relative;text-align: center;
        img{
            transform: scale(0.95);@include trans;margin: 0 auto;
            box-shadow: 0 0 30px rgba(255,255,255,0.2);
            &:last-child{display: none;}
        }
        &:hover{
            img{
                transform: scale(1);
                @include animation-anime(scale1,0.4s ,ease-in-out );
                &:first-child{display: none;}
                &:last-child{display: block;}
               
            }   
        }
    }
}
.text-container{
    .white-shadow{
        img{border-radius: 15px;}
    }
    .max-width-600{max-width: 600px;margin:0 auto 20px;}
    .img-pnl{
        position: relative;
        .bg-layer{background-size: 100% 100%;}
        img{position: relative;z-index: 2;max-width:80%;}
    }
    .txt{
        display: flex;height: 100%; flex-direction: column;
        justify-content: center;align-items: flex-start;
        @media(max-width:991px) {
            &.mobile-text-center{
                align-items: center;
                .reg-btn{width: 100%;}
            }
        }
    }
    p{font-size: 20px;}
    .text-center{
        p{margin: 0 auto;}
    }
    .anime-img-panel{
        position: relative;display: flex;padding: 0 30px;height: 520px;max-width:570px;
        z-index: 10;justify-content: center;align-items: center;
        @media(max-width:1200px) {width: 100%;height: 430px;max-width:450px;padding:0;
            .angle-img{max-width: 380px}
        }
        @media(max-width:575px) {height: 300px;max-width:250px;
            .angle-img{max-width: 220px}
        }
        @media(max-width:991px) {margin: 30px auto 0;}
        img{@include trans;}
        .img-pnl{position: absolute;width: 100%;height: 100%;left: 0;right: 0;
            .img-pnl-inner{width: 100%;height: 100%;position: relative;
                img{position: absolute;box-shadow:0 0 70px rgba(0,0,0,0.75);transform: scale(1.05);
                    @media(max-width:1200px) {max-width: 250px;}
                    @media(max-width:575px) {max-width: 160px;}
                    &:first-child{left: 0px;top: -40px;
                        z-index: 2;
                        @media(max-width:1200px) {left: 0px;top: 30px;}
                        @media(max-width:575px) {left: 0px;top: -20px;}
                    }
                    &:last-child{
                        right: 30px;bottom: 20px;
                        z-index: 0;
                        @media(max-width:1200px) {right: 0;bottom: 100px;}
                    }
                    
                }
            }
        }
        span{position: absolute;opacity: 0.7;z-index: 1;
            &:first-child{width: 48px;height: 54px;left: -22px;top: 90px;
                background-image: url(../Assets/img/Posts/arrow-anime-1.png);
                background-size: 100% 100%;
                @media(max-width:1200px) {left: -15px;top:60px;}
                @media(max-width:575px) {left: -15px;top: 30px;width: 28px;height: 34px;}
            }
            &:nth-last-of-type(2){width: 36px;height: 42px;right: 70px;top: 80px;
                background-image: url(../Assets/img/Posts/arrow-anime-2.png);
                background-size: 100% 100%;
                @media(max-width:1200px) {right: 70px;top: 60px;}
                @media(max-width:575px) {right: 0;top: 50px;width: 26px;height: 32px;}
            }
            &:nth-of-type(3){width: 41px;height: 47px;bottom: 100px;right: 38%;
                background-image: url(../Assets/img/Posts/arrow-anime-3.png);
                background-size: 100% 100%;
                @media(max-width:1200px) {bottom: 120px;right: 24%;}
                @media(max-width:575px) {bottom: 80px;right: 24%;width: 26px;height: 27px;}
            }
        }
        &:before{
            position: absolute;content: "";
            left: 0;right: 0;bottom: 0;top: 0;
            background-image: url(../Assets/img/Posts/shadow.png);
            background-size: 100% 100%;
            z-index: -1;opacity: 0.7;
        }
        @media(min-width:992px) {
            &:hover{
                .angle-img{transform: rotate(-15deg);
                        @media(max-width:575px) {transform: unset;}
                }
                .img-pnl .img-pnl-inner img{
                        transform: scale(0.9);
                        // @media(max-width:1200px) {transform: scale(1);}
                        // &:first-child{left: 0px;z-index: 4;top: -20px;}
                        // &:last-child{right: 50px;bottom:0px;
                        //     @media(max-width:1200px) {left: 0;bottom: 10px;}
                        // }
                }
                span{
                        &:first-child{width: 60px;height: 70px;left: -80px;top: 85px;
                            @media(max-width:1200px) {left: -20px;width: 30px;height: 40px;top: 65px;}
                            @media(max-width:575px) {left: -15px;top: 30px;width: 28px;height: 34px;}
                            
                        }
                        &:nth-last-of-type(2){width: 50px;height: 55px;right: 25px;top: 35px;
                            @media(max-width:1200px) {width: 30px;height: 35px;right: 35px;top: 45px;}
                            @media(max-width:575px) {right: 0;top: 50px;width: 26px;height: 32px;}
                        
                        }
                        &:nth-of-type(3){width: 65px;height: 70px;bottom: 120px;right: 22%;
                            @media(max-width:1200px) {width: 35px;height: 40px;}
                            @media(max-width:575px) {bottom: 80px;right: 24%;width: 26px;height: 27px;}
                            
                        }
                }
            }
        }
    }
  
}
.anime-text-pnl{
    display: flex;justify-content: space-between;padding-left:70px;align-items: center;
    @media(max-width:1200px) {padding-left: 0;}
    @media(max-width:991px) {display: inline-block;width: 100%;}
    .txt{width: calc(100% - 570px);padding-left: 15px;
        @media(max-width:1200px){width: calc(100% - 450px);padding-left:0;}
        @media(max-width:991px){width:100%;}
    }
}
.text-container{
    .anime-text-pnl.get-panel {
        .anime-img-panel {
            .angle-img{
                transform: rotate(224deg);position: relative;top: -140px;opacity: 0.8;
                @media(max-width:1200px){top: -50px;margin-right: 50px;}
            }
            span{
                &:first-child{left: -80px;top: 45%;transform: rotate(-10deg);
                    @media(max-width:1200px){left: 0;}
                    @media(max-width:575px){top:100px;left:-32px;}
                }
                &:nth-of-type(2){right: -20px;top: 130px;transform: rotate(34deg);
                    @media(max-width:1200px){right: 0;}
                    @media(max-width:575px){top:50px;right:-36px;}
                }
                &:nth-of-type(3){bottom: 20px;right: 30%;transform: rotate(10deg);}
            }
            .img-pnl {
                .img-pnl-inner {
                    img{
                        &:first-child{right: 50px;bottom: 100px;top: unset;
                            @media(max-width:1200px){max-width: 300px;}
                            @media(max-width:575px){max-width: 200px;right:0;}
                        }
                        &:last-child{max-width: 150px;bottom: 90px;left: -20px;
                            @media(max-width:1200px){max-width: 90px;left:50px;}
                            @media(max-width:575px){max-width: 70px;left:0;}
                        }
                    }
                }
            }
            @media(min-width:992px) {
                &:hover {
                        .angle-img{
                            transform: rotate(200deg);position: relative;top: -60px;margin-right: 100px;
                            @media(max-width:1200px){margin-right: 20px;}
                        }
                        .img-pnl {
                            .img-pnl-inner {
                                img{
                                    transform: scale(1);
                                    @media(max-width:1200px) {transform: scale(1);}
                                    &:first-child{max-width: 400px;
                                        @media(max-width:1200px){max-width: 300px;}
                                    }
                                    &:last-child{max-width: 130px;
                                        @media(max-width:1200px){max-width: 90px;left:0px;}
                                    }
                                }
                            }
                        }
                    span{
                        &:first-child{left: 18px;top: 54px;transform: rotate(30deg);}
                        &:nth-of-type(2){right: 8px;top: 0;transform: rotate(-12deg);}
                        &:nth-of-type(3){width: 41px;height: 47px;bottom: -15px;right: 19%;transform: rotate(10deg);}
                    }         
                }
            }
        }
    }
}
// Dot List
.dot-list{
    li{
        width: 100%;display: inline-block;position: relative;text-align: left;margin-bottom: 10px;font-size: 18px;
        color: $white;padding-left:20px;
        &:before{
            position: absolute;content: "";
            left: 0;top: 12px;width: 5px;height: 5px;background-color: $white;border-radius: 50%;
        }
    }
    &.margin-less li{margin: 0;font-weight: 400;}
}
// Dot List

.stage-container-panel{
    position: relative;
    .stage-container-panel-inner{
        position: relative;width: 100%;border-radius: 20px;border:1px solid $darkgrey;
        text-align: center;padding:50px 20px;box-shadow:0 0 20px rgba(255,255,255,0.2) ;
        @media(max-width:991px){padding: 30px 0px;}
        .bg-layer{opacity: 0.2;border-radius: 20px;}
        .my-token-list.trans li p{@include Font-chakra;font-size: 22px;
            @media(max-width:1200px){font-size: 18px;}
            @media(max-width:991px){font-size: 22px;}
            @media(max-width:767px){font-size: 18px;}
            @media(max-width:575px){font-size: 14px;}
        }
        .bonus-percentage-list{
            max-width: 650px;
            @media(max-width:767px){
                h3{text-align: center;padding-left: 15px;}
                p{text-align: left;}
            }
        }
        .confirm-btn{
            margin-left: 40px;
            @media(max-width:767px){margin-left: 0;width: 100%;margin-top:20px;}
        }
    }

}
.dark-list{
    background-color: #1A1A1A;border-radius: 20px;padding: 20px 20px 15px;text-align: center;margin-bottom:30px;
    @media(min-width:768px){display: flex;justify-content: space-between;align-items: center;}
    @media(max-width:1200px){padding: 20px 0px 15px;}
    p{font-size: 18px;color: $white;margin: 0;display: flex;justify-content: center;align-items: center;
        @media(max-width:1200px){font-size: 15px;}
    }
    p b{font-size: 32px;@include Font-chakra;
        @media(max-width:1200px){font-size: 24px;}
        @media(max-width:991px){font-size: 28px;}
        @media(max-width:767px){font-size: 26px;margin-top:5px;}
    }
    li{position: relative;padding:0 15px;
        img{max-width: 25px;margin: 0 5px;}
        &:not(:first-child):before{
            position: absolute;content: "";left: -30px;top: 10px;height: 50px;width: 1px;background-color: $grey;
            @media(max-width:1400px){left: 0;}
            @media(max-width:767px){display: none;}
        }
    }
}
.stage-1{
    .tablet-view{
        .dark-list{
            @media(max-width:991px){
                max-width: calc(100% - 30px);
                margin-left: 15px;
            }
        }
    }
}
@media(max-width:991px){
    .step-1,
    .step-2{
        max-width: calc(100% - 30px);
    }
}
// Join Presale Now Panel
.join-presale-now{position: relative;z-index: 1;padding-top:50px;padding-bottom: 50px;
    @media(max-width:991px){padding-top: 50px;}
    @media(max-width:767px){padding-top: 30px;}
    .join-presale{
        position: relative;z-index: 2;padding:50px 50px;border-radius: 20px;height:550px;z-index: 2;text-align: left;
        display: flex;flex-direction: column;justify-content: flex-start;align-items: flex-start;
        &:before{
            position: absolute;content: "";left: 0;right: 0;bottom: 0;top: 0;background-image: url(../Assets/img/Bg/presale-bg-3.png);background-repeat: no-repeat;
            background-attachment: fixed;background-position: center center;background-size: 70% auto;
        }
        .bg-layer{border-radius: 15px;z-index: -1;overflow: hidden;
            background-repeat: no-repeat;background-position: center bottom;
        }
        @media(max-width:1200px){padding-top: 40px;padding-bottom:32.5px;}
        h1{position: relative;display: inline-block;z-index: 2;margin-bottom:0px;}
        h4{color: $white;}
    }
}
.mobile-break{
    @media(max-width:991px){
        br{display: none;}
    }  
}

.artificial-pnl{
    position: relative;padding-bottom: 300px;z-index: 2;
    .bg-layer{background-size: auto;background-repeat: no-repeat;background-position: center bottom;z-index: -2;}
    &:before{
        position: absolute;content: "";left: 0;right: 0;bottom: 0;height: 200px;z-index: -1;
        background: linear-gradient(360deg, #000000 0%, rgba(8, 21, 39, 0.87) 51%, rgba(40, 62, 87, 0) 100%);
    }
}
.incom-pnl{
    position: relative;
    padding-top: 100px;
    padding-bottom: 100px;
    .bg-layer{
        background-size: 100% auto;
        background-repeat: no-repeat;
    }
}
.incom-list{
    display: flex;width: 100%;margin:0;
    margin-bottom: 20px;
    justify-content: center;
    flex-wrap: wrap;
    &:before{
        position: absolute;
        content: "";
        left: 18%;right: 18%;
        height: 1px;
        top: 0;
        border: 0.1px dotted $yellow;
        opacity: 0.2;
        @media(max-width:991px){display: none;}
    }
    li{
        padding: 0 15px;margin-bottom: 30px;text-align: center;
        display: inline-block;width: 33.33333%;
        position: relative;
        @media(max-width:991px){width: 50%;}
        @media(max-width:767px){width: 100%;}
        &:before{
            position: absolute;
            content: "";
            height: 15px;
            width: 15px;
            top: -7px;
            border-radius: 50%;
            background-color: $yellow;
            
        @media(max-width:991px){display: none;}
        }
        .img-pnl{
            position: relative;text-align: center;width: 220px;
            height: 220px;display: flex;justify-content: center;align-items: center;
            margin: 0 auto;
            
            @media(max-width:1200px){height:180px;}
            img{max-width: 200px;
                @media(max-width:1400px){max-width: 150px;}
                @media(max-width:1200px){max-width: 120px;}
            }
        }
        .txt-pnl{
            h4{@include Font-outfit;color: $color;font-weight: 800;@include Font-chakra;}
            p{@include Font-outfit;font-size: 18px;max-width: 350px;margin: 0 auto;height: 81px;overflow: hidden;
                span{color: $yellow;}
            }
        }
        &:hover{
            span{transform: rotate(-10deg) scale(1.4);}
        }
    }
}

.about-pnl{
    position: relative;
    .bg-layer{
        background-size: auto;
        background-repeat: no-repeat;
    }
}
.about-list{display: flex;width: 100%;padding:70px 0;justify-content: center;flex-wrap: wrap;gap: 15px;position: relative;z-index: 2;
    &:before{position: absolute;content: "";left: 0;top: 0;right: 0;height: 50px;z-index: 1;
            background: linear-gradient(to bottom,  rgba(0,0,0,1) 0%,rgba(0,0,0,0) 100%); 
    }
    &:after{position: absolute;content: "";left: 0;bottom: 0;right: 0;height: 50px;z-index: 1;
        background: linear-gradient(to top,  rgba(0,0,0,1) 0%,rgba(0,0,0,0) 100%); 
    }
    .bg-layer{background-repeat: no-repeat;background-size: 100% auto;z-index: -1;background-attachment: fixed;z-index: -2;}
    
    li{
        padding: 20px 35px 30px;margin-bottom: 30px;text-align: left;width: calc(33.3333% - 15px);border-radius: 20px;
        background: linear-gradient(to left, rgba(21, 21, 21, 0.1) 0%, rgba(66, 66, 66, 0.1) 100%);
        backdrop-filter: blur(104px);display: flex;flex-direction: column;justify-content: center;
        @media(max-width:767px){padding: 20px 0;}
        h5{text-transform: uppercase;}
        &:nth-of-type(1){padding: 0;background: unset;border-radius: 0;backdrop-filter: unset;
            .btn-list{gap: 10px;justify-content: flex-start;margin:0;
                li{padding: 0;background: unset;width: auto;margin: 0;
                    .reg-btn{font-size: 14px;}
                }
            }
        }
        @media(max-width:991px){width: calc(50% - 15px);}
        @media(max-width:767px){width: 100%;}
        .img-pnl{
            position: relative;text-align: center;width: 120px;height: 120px;display: flex;justify-content: flex-start;align-items: center;
            img{max-width: 50px;}
        }
        .txt-pnl{
            h4{@include Font-outfit;color: $color;font-weight: 800;@include Font-chakra;}
            p{@include Font-outfit;font-size: 16px;max-width: 350px;font-weight:300;
                span{color: $yellow;}
            }
        }
    }
}
.index-list{
    display: flex;position: relative;
    flex-direction: column;z-index: 5;
    li{
        display: flex;border-radius: 10px;padding: 10px 20px;@include trans;
        margin-bottom: 10px;align-items: center;position: relative;
        &:before{
            position: absolute;content: "";left: 0;right: 0;top: 0;bottom: 0;@include trans;
            border-radius: 10px;background-color: rgba(255,255,255,0.2);
        }
        &:after{
            position: absolute;content: "";left: 0;right: 0;top: 0;bottom: 0;opacity: 0;@include trans;
            border-radius: 10px; background: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0.24) 100%);
        }
        h4{color: $color;@include Font-outfit;font-weight: 700;
            text-transform: uppercase;display: flex;align-items: center;
            img{margin-left: 15px;max-width: 15px;}
            @media(max-width:575px){font-size: 18px;}
        }
        p{margin: 0;@include Font-outfit;font-size: 20px;line-height: 24px;
            @media(max-width:575px){font-size: 16px;line-height: 20px;}
        }
        @media(max-width:575px){
            text-align:left;padding:10px 10px 20px;
            img{max-width: 60px;}
        }
        &:hover{
            &:before{opacity: 0;}
            &:after{opacity: 1;}
        }
    }
}
.index-pnl{
    padding-top: 150px;padding-bottom: 150px;position: relative;
    @media(max-width:1200px){padding-top: 80px;padding-bottom:50px;}
    .bg-layer{opacity: 0.7;background-repeat: no-repeat;}
    // &::before{
    //     position: absolute;content: "";
    //     left: 0;top: 0;right: 0;height: 150px;z-index: 3;
    //     background: linear-gradient(to bottom,  rgba(0,0,0,1) 0%,rgba(0,0,0,0) 100%);

    // }
    // &::after{
    //     position: absolute;content: "";
    //     left: 0;bottom: 0;right: 0;height: 150px;z-index: 3;
    //     background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%);
    // }
}
// Join Presale Now Panel

.profit-launch-post{
    position: relative;height: 100vh;width: 100%;position: relative;
    .bg-layer{z-index: -1;opacity: 1;background-repeat: no-repeat;
        background-size: 52% auto , 100% auto;background-position: 70% center, center center;
    }
    .bg-layer-1{
        position: absolute;content: "";left: 0;right: 0;bottom: 0;top: 0;
        z-index: 1;opacity: 1;background-repeat: no-repeat;background-size: 100% auto;background-position: center center;background-attachment: fixed;
        @media(max-width:1200px){background-size: 80% auto;}
    }
    &:after{
        position: absolute;content: "";left: 0;right: 0;top: 0;height: 25%;z-index: 2;
        background: linear-gradient(180deg, #000000 30%, rgba(7, 0, 6, 0) 100%);
        @media(max-width:991px){height: 10%;}
    }
    &:before{
        position: absolute;content: "";left: 0;right: 0;bottom: 0;height: 25%;z-index: 2;
        background: linear-gradient(360deg, #000000 30%, rgba(7, 0, 6, 0) 100%);
        @media(max-width:991px){height: 10%;}
    }
    .profit-launch-post-inner{
        position: relative;width: 100%;height: 100%;display: flex;justify-content: center;align-items: center;
        @media(max-width:1400px){padding: 200px 0px;height:unset;}
        @media(max-width:991px){padding: 50px 0px;}
        &:after{
            position: absolute;content: "";left: 0;top: 0;bottom: 0;width: 250px;
            background: linear-gradient(to right,  rgba(0,0,0,1) 0%,rgba(0,0,0,0) 100%); 
            @media(max-width:991px){width: 150px;}
        }
        &:before{
            position: absolute;content: "";right: 0;top: 0;bottom: 0;width: 250px;
            background: linear-gradient(to left,  rgba(0,0,0,1) 0%,rgba(0,0,0,0) 100%); 
            @media(max-width:991px){width: 150px;}
        }   
    }
    @media(max-width:1400px){padding: 0px 70px;height:unset;}
    @media(max-width:1200px){padding: 0px 60px;height:unset;}
    @media(max-width:991px){padding: 0px 50px;}
    @media(max-width:767px){padding: 0px 15px;flex-direction: column;}
    h2{position: relative;margin:0;font-size: 64px;z-index: 2;line-height: 54px;
        @media(max-width:1400px){font-size: 44px;line-height: 40px;}
        @media(max-width:991px){font-size: 32px;line-height: 32px;}
        @media(max-width:767px){font-size: 24px;}
        @media(max-width:575px){font-size: 16px;}
        span{color: $white;-webkit-background-clip: #fff !important;background-clip: unset;-webkit-text-fill-color: unset !important;
            font-size: 150px;font-weight: 500;margin-top: 45px;display: inline-block;width: 100%;
            @media(max-width:1400px){font-size: 120px;}
            @media(max-width:1400px){font-size: 80px;margin-top:25px;}
        }
        &:before{display: none;}
        &.bg-text{margin-right: 10px;}
    }
}
.color-red{color: red;}
.CircularProgressbar .CircularProgressbar-path {stroke: #c1c102 !important;}  
.center-allign{text-align: -webkit-center !important;}
.center-text-allign{text-align: center;width: 100%;}
.reg-btn-connect {padding: 11px 35px;
    /* background: linear-gradient(135deg, rgba(224, 224, 38, 0) 0%, #bdbd0b 100%); /
    / background-color: #DBDB02; */
    border: none;font-weight: 800;color: #000000;text-transform: uppercase;}
.bg-container{position: relative;
    .bgg-layer{
        position: absolute;
        left: 0;right: 0;top: 150px;bottom: 0;
        background-attachment: fixed;
        background-size: cover;opacity: 0.1;
        background-position: center top;
    }
}
