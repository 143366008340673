.admin-panel{
    background-image: url(../Assets/img/Bg/dashboard-bg.png);
    background-repeat: no-repeat;background-size: aut;background-position: top center;
    min-height: 100vh;padding-top: 180px;position: relative;
    padding-bottom: 20px;
    @media(max-width:991px){padding-top: 120px;}
    p{color: $bordercolor;}
    .tooltip-span{position: relative;display: inline-block;
        img{position: relative;top: -3px;margin-left: 5px;}
        span{
            font-size: 14px;font-weight: 500;position: absolute;right: 0%;bottom: 80%;
            display: none;display: none;background-color: $dark;border-radius: 3px;padding: 5px 10px;
            border:1px solid $darkgrey;min-width: 180px;color: $white;z-index: 5;
        }
        &:hover span{display: inline-block}
        &.lefty{
            span{right: unset;left: 0;
                @media(max-width:575px){max-width: 150px;overflow: hidden;min-width: 100px;}
            }
        }
    }
}
@media(min-width:768px){
    .web-text-center{
        text-align: center;
    }
}
@media(max-width:767px){
    .m-w-100{width: 100%;}
    .token-text-cntnr{
         >span{width: 100%;display: inline-block;margin-left: 0 !important;margin-right: 0 !important;}
    }
}
.step-section{display: none;
    &.block{display: inline-block;}
}
.green-post{
    padding: 15px 20px;background-color: $dark;margin-bottom: 10px;
    border-radius: 15px 15px 0 0;
    h6{font-weight: 400;}
}
.reject-span{
    color: $redcolor;font-size: 16px;font-weight: 700;display: inline-block;
    border-radius: 25px;padding: 2px 10px;text-transform: uppercase;min-width:84.14px;
    text-align: center;letter-spacing:3px;
    .active{display: none;}
    &.active{background-color: transparent;color:#74934E;
        .disable{display: none;}
        .active{display: inline-block;}
    }
}
.factor-status-panel{
    max-width: 650px;margin:40px auto 0;
}
.blue-post{@include post;padding: 30px;margin-bottom: 30px;position: relative;
    background: linear-gradient(to bottom,  rgba(0,0,0,1) 45%,rgba(26,26,26,1) 100%);
    box-shadow: 0 0 30px rgba(255,255,255,0.20);border: 2px solid #545454;

    @media(max-width:767px){padding: 30px 15px;}
    @media(max-width:575px){padding: 30px 15px 15px;}
   .inner-padding{padding: 30px;
    @media(max-width:767px){padding: 30px 15px;}
   }
    &.token-blnce-pnl{
        .heading-pnl{width: 50%;padding-right: 15px;
            @media(max-width:767px){text-align: left;width:100%;padding-left:0;}
        }
        .stage-panel{width: 200px;
            .bonus-percentage-list li.circle-heading{width: 200px;min-width: 200px;height: 200px;}
            @media(max-width:767px){margin: 0 auto 30px;}
        }
        .txt-pnl{position: relative;width: 50%;padding:27px 0px 27px 15px;

            @media(max-width:767px){text-align: left;padding:30px 0px;width:100%;}
            &:before{
                position: absolute;content: "";
                left: -20px;top: 0;bottom: 0;width: 2px;background-color: $blue;
                @media(max-width:767px){
                    width: 100%;bottom: unset;left:0;right:0;height:2px;top:10px;
                }
            }
        }
        .text-pnl{
            width: calc(100% - 200px);padding:0px 0px 0px 15px;position: relative;
            @media(max-width:767px){width: 100%;
                .reg-btn{width: 100%;}
            }
        }
    }
}
.bonus-percentage-list{
    display: flex;width: 100%;justify-content: space-between;
    max-width: 80%;margin:0px auto 0;text-align: center;position: relative;z-index: 2;
    @media(max-width:767px){max-width: 100%;display:inline-block;width: 100%;margin-top:20px;margin-bottom:0;}
    li{
        @media(max-width:767px){display: flex;align-items: center;margin-bottom:10px;}
        &.circle-heading{position: relative;width: 250px;height:250px;display: inline-flex;min-width:250px;
            justify-content: center;align-items: center;flex-direction: column;
            &:before{
                position: absolute;content: "";
                left: 0;right: 0;bottom: 0;top: 0;
                background-image: url(../Assets/img/admin-icons/circle-bg.png);
                background-repeat: no-repeat;background-position: center center;
                background-size: 100% 100%;z-index: -1;
            }
        }
        h3{
            color: $white;display:inline-block;position: relative;z-index: 2;padding: 15px 15px 30px;margin:0;@include Font-chakra;
            @media(max-width:767px){padding-left: 0;}
            span{display: inline-block;width: 100%;text-transform: uppercase;font-weight: 800;font-size: 14px;letter-spacing: 5px;}
            &:before{
                position: absolute;content: "";
                left: 0;right: 0;bottom: 0;top: 0;
                background-image: url(../Assets/img/admin-icons/icon-bg.png);
                background-repeat: no-repeat;background-position: center center;
                opacity: 0.5;z-index: -1;
                transform: rotate(-45deg);
            }
        }
        h1{color: $white;display:inline-block;position: relative;z-index: 2;margin:0;
            font-size: 32px;text-align:center;@include Font-chakra;
            span{display: inline-block;width: 100%;text-transform: uppercase;font-size: 18px;letter-spacing: 5px;}
        }
        &:first-child h3:before{
            transform: rotate(-0deg);
        }
        &:last-child h3:before{
            transform: rotate(45deg);
        }
        p{font-size: 18px;margin: 0;font-weight: 700;
            span{color: $yellow;display: inline-block;padding: 0 5px;}
        }   
    }
    &.full{
        max-width: 100%;
        li{width: 100%;padding: 0;}
    }
}
.purple-post{
    display: inline-block;width: 100%;background-color: $dark;
    border-radius: 10px;padding: 15px 10px;margin-bottom:30px; text-align: left;
    h2 span{font-size: 32px;color: $bordercolor;font-weight: 300;
        @media(max-width:991px){margin-bottom: 26px;}
        @media(max-width:767px){margin-bottom: 20px;}
        @media(max-width:575px){margin-bottom: 16px;}
    }

}
.my-token-list{display: inline-block;width: 100%;background-color: $dark;
    border-radius: 10px;padding: 15px 10px;margin:0;
    width: calc(100% - 280px);
    @media(max-width:991px){margin-bottom: 30px;}
    @media(max-width:767px){width: 100%;}
    @media(max-width:575px){padding: 15px 0;}
    li{
        display: flex;justify-content: space-between;align-items: center;position: relative;
        &:before{
            position: absolute;content: '';
            left: 0;right: 0;bottom: 7px;height: 1px;
            border-bottom: 1px dashed rgba(255,255,255,0.5);
        }
        p{margin: 0;font-size:18px;background-color:$dark;padding: 0 10px;position: relative;z-index:2;
            span{color: $white;font-weight: 700;display: inline-block;margin-right: 10px;}
            @media(max-width:767px){font-size: 16px;padding:0 5px;
                b{font-size: 18px;}
            }
        }
        &.blue{
            p{color: $color;}
            &:before{
                border-color:$color;
            }
        }
    }
    &.trans{background-color: transparent;
        li{
            &:before{display: none;}
            .border-span{
                border-bottom: 2px dotted rgba(255,255,255,0.5); flex-grow: 1;
                position: relative;top:10px;

            }
            p{background-color: transparent;}
        }
    }
    @media(max-width:767px){
        &.mb-none{
            li{display: inline-block;width: 100%;text-align: left;}
        }
    }
}
.chat-span{
    background-color: $color;position: fixed;
    bottom: 40px;right: 40px;z-index: 999;display: inline-flex;
    justify-content: center;align-items: center;padding: 0;
    width: 60px;height:60px;border: none;border-radius: 50%;
    img{max-width: 30px;}
    &:active,
    &:focus,
    &:hover{background-color: $yellow;}
}

.Onmobile{

    @media(min-width:768px){
         display: none;
    }
}

.css-zlg4nw-control{
    border-radius: 5px !important;
    height: 42px;
    *{height: 42px;}
    .css-1xc3v61-indicatorContainer{padding: 0 5px;}
    .css-1u9des2-indicatorSeparator{height: auto;}
}

.progress-bar{
    display: inline-block;width: 100%;overflow: visible;
    position: relative;height:10px;border-radius: 25px; margin-top: 10px;
    background:#787878;
    .bar{
        background: #FFD700;
        position: absolute;left:0;top:0;bottom:0;border-radius: 25px;z-index: 1;
    }
    .measure-bar{
        position: absolute;right:-20px;
        top: -17px;z-index: 22;
    }
}
.custom-progress-bar{
    background: #787878 !important;
height: 0 !important;
}
canvas{max-width: 100%;}

@media (max-width: 767px) {
    .admin-panel .tooltip-span img {
        display: none;
    }
}
.copies-icon{
    display: none;
}
@media (max-width: 767px) {
    .copies-icon {
        position: relative;margin-left: 5px;
        display: inline-block;
        margin-right: 6px;
    
    }
}