.simple-link{color: $white;text-decoration: underline !important;
    background-color: transparent !important;border:none;padding:0;
    &.blue,
    &.purple{color: $blue;
        &:hover{color: $color;}
    }
    &.yellow{
        color: $yellow;
        &:hover{color: $color;}
    }
    &:hover{color: $color;}
}
.remove-btn{
    .removed{display: none;}
    &.remove{
        .add{display: none;}
        .removed{display: initial;}
    }
}
.reg-btn{
    padding: 13px 30px;background-color: $yellow;border: none;font-size: 18px;text-align: center;
    font-weight: 700;color:$black;text-transform: uppercase;position: relative;z-index: 2;
    &:before{
        width: 100%;height: 100%;content: "";position: absolute;left: 0;top: 0;
        z-index: -1;@include gradinet1;@include trans;
    }
    &:after{
        width: 100%;height: 100%;content: "";position: absolute;left: 0;top: 0;
        z-index: -1;@include gradinet2;@include trans;opacity: 0;
    }
    @media(max-width:1200px){padding: 11px 26px;font-size:16px;}
    @media(max-width:991px){padding: 11px 22px;font-size:18px;}
    @media(max-width:575px){padding: 11px 18px;}
    i{margin-right: 5px;font-size: 20px;}
    &:hover{color: $black;
        &:before{opacity: 0;}
        &:after{opacity: 1;}
    }
    &.br-radius{border-radius: 30px;
        &:after,
        &::before{border-radius: 30px;}
    }
    &.small{padding: 7.5px 25px;}
    &.big{padding: 18px 45px;font-size: 18px;}
    &.blue{background-color: #3498DB;color: $white;border-radius: 3px;background-image: none;padding: 10px 10px;text-transform: capitalize;font-weight: 400;
        @media (max-width:767px) {min-width: 187px;}
    }
    &.empty{padding-left: 0;background: none !important;padding-right:0;
        &::before,
        &:after{display: none;}
    }
    &.trans{
        background-color: transparent;
        background-image: none;font-weight: 600;
        color: $white;border: 1px solid $white;z-index: 2;position: relative;
        &::before{
            position: absolute;content: "";z-index: -1;border-radius: 30px;
            left: -1px;right: -1px;bottom: -1px;top: -1px;opacity: 0;@include trans;
            background: $white; 
        }
        &:after{display: none;}
        &:hover{color: $black;border-color: transparent;box-shadow:none !important;
            &::before{opacity: 1;}
            
        }
    }
}
.w-48{width: 48%;}