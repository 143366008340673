footer{position: relative;padding-top: 50px;padding-bottom: 150px;text-align: center;
    background-color: $black;
    @media(max-width:991px){padding-bottom: 50px;}
    h6{color: $white;text-transform: uppercase;font-weight: 700;}
    ul{
        li{display: inline-block;margin: 0 20px;color: $white;
            @media(max-width:1400px){margin: 0 15px;}
            @media(max-width:991px){margin: 0 20px;}
            @media(max-width:575px){font-size: 16px;margin:0 10px;}
        }
        a{color: $white;padding: 3px 0;font-size: 18px;text-transform: capitalize;
            &.active,&:hover{color: $color;}
            @media(max-width:1200px){font-size: 18px;}
        }
        &.footer-list{margin-top: 10px;
            @media(max-width:991px){padding-top: 20px;}
        }
        &.footer-social-list{margin-top: 10px;cursor: pointer;
            li{margin: 0 5px;
                a{opacity: 0.8;
                    &:hover{opacity: 1;}
                    @media(max-width:991px){opacity: 1;}
                }
            }
        }
        &.bottom-list{position: absolute;left: 0;right: 0;bottom: 50px;z-index: 3;margin: 0;
            @media(min-width:768px){display: flex;flex-direction: row-reverse;align-items: center;justify-content: center;}
            @media(max-width:991px){bottom: 20px;}
            li{font-weight: 500;
                a{font-weight: 500;font-size:14px;text-decoration: underline !important;
                    @media(max-width:767px){font-size: 12px;}
                }
                @media(max-width:767px){
                    margin-left: 10px;margin-right:10px;font-size:12px;
                    &:last-child{width: 100%;margin-left: 0px;margin-right:0px;}
                }
            }
        }
    }
}