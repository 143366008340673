/*color*/
$white: #ffffff;
$black: #000000;
$color:#FF8A00;
$yellow:#FFD700;
$lighteryellow:#FFF6C5;
$grey:#BFBFBF;
$darkgrey:#313131;
$blue:#B5DBFF;
$dark:#191919;
$bordercolor:#DCDCDC;
$greencolor:#74934E;
$redcolor:#F3545E;
$darkblue:#13D5FF;
$purple:#96A8FC;
$lightpurple:#CECEF4;