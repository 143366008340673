
/* Mixins */
@mixin trans {
    -webkit-transition: all .5s ease-in-out;
    -moz-transition: all .5s ease-in-out;
    -ms-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
}
@mixin Font-titllium{font-family: "Titillium Web", sans-serif !important;}
@mixin Font-outfit{font-family:"Outfit", sans-serif !important;}
@mixin Font-chakra{font-family: "Chakra Petch", sans-serif !important;}
@mixin gradient{ 
    background: linear-gradient(180deg, rgba(7, 15, 43, 0) 0%, #540505 100%);
}

@mixin gradinet1{background: linear-gradient(to left,  rgba(244,195,25,1) 0%,rgba(214,146,25,1) 25%,rgba(248,214,72,1) 48%,rgba(239,186,55,1) 73%,rgba(229,193,73,1) 100%);}
@mixin gradinet2{background: linear-gradient(to right,  rgba(244,195,25,1) 0%,rgba(214,146,25,1) 25%,rgba(248,214,72,1) 48%,rgba(239,186,55,1) 73%,rgba(229,193,73,1) 100%); }
@mixin hoverboxshadow{box-shadow: 0 0 40px #FFB800 !important;}
@mixin post{
    border-radius:20px;position: relative;
    background: linear-gradient(to bottom, rgb(0, 0, 0) 45%, rgb(26, 26, 26) 100%);
    box-shadow: 0 0 30px rgba(255, 255, 255, 0.2);
    border: 2px solid #545454;
}
@mixin textgradient{
    background:  linear-gradient(to right,  rgba(255,209,99,1) 20%,rgba(252,237,200,1) 73%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}


@mixin boxshadow{box-shadow: 0 0 10px 10px rgba(83,53,242,0.05);}

@mixin topshedow{
    position: absolute;content: "";left: 0;right: 0;top: 0;height: 25%;z-index: 1;
    background: linear-gradient(to bottom,  rgba(6,2,30,1) 0%,rgba(6,2,30,0) 100%);
}
@mixin bottomshedow{
    position: absolute;content: "";left: 0;right: 0;bottom: 0;height: 25%;z-index: 1;
    background: linear-gradient(to top,  rgba(6,2,30,1) 0%,rgba(6,2,30,0) 100%);
}
/* In This 5px is by default */
@mixin bd-radius($value : 5px) {
    -webkit-border-radius: $value;
    -moz-border-radius: $value;
    border-radius: $value;
}
/* Border Radius */

/* Animation */
// @include animation-anime(Navanime, 1.2s, linear);
@mixin animation-anime($animname, $animetime, $flow) {
    -webkit-animation: $animname $animetime $flow;
    -moz-animation: $animname $animetime $flow;
    animation: $animname $animetime $flow;
}

@mixin animationanime($animname, $animetime, $flow, $stay, $altr) {
    -webkit-animation: $animname $animetime $flow $stay $altr;
    -moz-animation: $animname $animetime $flow $stay $altr;
    animation: $animname $animetime $flow $stay $altr;
}
/* Animation */

/* Mixins */